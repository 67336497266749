import { createContext, useMemo, useState, useContext, useEffect } from "react";

import { SessionContext, SessionContextValue } from "../Session/SessionContext";
import NotificationComponent, {
  INotificationProps
} from "../../Components/CustomComponents/NotificationComponent/Index";
import HttpRequest, { IRequestProps } from "../../Utilities/ApiHelper/HttpRequest";

export type ModuleTemplateContextValue = {
  moduleTemplate: IModuleTemplate[];
  setModuleTemplate: (moduleTemplate: IModuleTemplate[]) => void;
};

export interface IModuleTemplate {
  id: number;
  attributes: {
    name: string;
    module: string;
  };
  type: string;
}

export const ModuleTemplateContext = createContext<ModuleTemplateContextValue | null>(null);

type ModuleTemplateProviderProps = {
  children: React.ReactNode;
};

const ModuleTemplateProvider = (props: ModuleTemplateProviderProps) => {
  const { session } = useContext<SessionContextValue>(SessionContext);
  const [moduleTemplate, setModuleTemplate] = useState<IModuleTemplate[]>(undefined);
  const [isApiFailed, setIsApiFailed] = useState(false);
  const getModuleTemplateRequestProps: IRequestProps = {
    method: "GET",
    session,
    url: "/moduleTemplates"
  };

  useEffect(() => {
    if (session.sessionToken !== undefined) {
      HttpRequest(getModuleTemplateRequestProps)
        .then((res) => {
          setModuleTemplate(res?.data);
        })
        .catch(() => {
          setModuleTemplate([]);
          setIsApiFailed(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.sessionToken]);

  const value = useMemo(
    () => ({
      moduleTemplate,
      setModuleTemplate
    }),
    [moduleTemplate]
  );

  const errorNotificationProps: INotificationProps = {
    notificationType: "error",
    isOpen: isApiFailed,
    onClose: () => setIsApiFailed(false)
  };

  return (
    <ModuleTemplateContext.Provider value={value}>
      {isApiFailed && <NotificationComponent {...errorNotificationProps} />}
      {props.children}
    </ModuleTemplateContext.Provider>
  );
};

export default ModuleTemplateProvider;
