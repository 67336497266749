import { useContext, useState } from "react";

import { Button } from "@emisgroup/ui-button";
import OutlineHelp from "~icons/ic/outline-help-outline";
import NotificationComponent, { INotificationProps } from "../../CustomComponents/NotificationComponent/Index";
import ModuleTemplateEnum from "../../../Utilities/ModuleTemplate/ModuleTemplateEnum";
import {
  IModuleTemplate,
  ModuleTemplateContext,
  ModuleTemplateContextValue
} from "../../../Contexts/ModuleTemplate/ModuleTemplate";
import { DispatchModuleTemplateEvent } from "../../../Utilities/ModuleTemplate/Index";

import "./SupportCentre.scss";

const SupportCentre = () => {
  const [isErrorNotificationOpen, setIsErrorNotificationOpen] = useState(false);
  const { moduleTemplate } = useContext<ModuleTemplateContextValue>(ModuleTemplateContext);

  const HandleButtonClick = () => {
    const selectedTemplate = moduleTemplate.find((item: IModuleTemplate) => {
      return item.attributes.name === ModuleTemplateEnum.SUPPORT_CENTRE;
    });

    if (selectedTemplate == null) {
      setIsErrorNotificationOpen(true);
      return;
    }

    DispatchModuleTemplateEvent(selectedTemplate.attributes.module);
  };

  const errorNotificationProps: INotificationProps = {
    notificationType: "error",
    isOpen: isErrorNotificationOpen,
    onClose: () => setIsErrorNotificationOpen(false)
  };

  return (
    <>
      {isErrorNotificationOpen && <NotificationComponent {...errorNotificationProps} />}
      <div>
        <Button
          data-dd-action-name="support-centre"
          className="supportcentre"
          borderless={true}
          data-testid="support-centre"
          onClick={HandleButtonClick}
        >
          <OutlineHelp title={""} className="online-training-icon"></OutlineHelp>
          Online Training
        </Button>
      </div>
    </>
  );
};

export default SupportCentre;
