import { useContext, useEffect, useState } from "react";

import { IWidget, WidgetContext, WidgetContextValue } from "../Contexts/Widget/WidgetContext";
import { SessionContext, SessionContextValue } from "../Contexts/Session/SessionContext";
import HttpRequest, { IRequestProps } from "../Utilities/ApiHelper/HttpRequest";
import { BannerContext, BannerContextValue, IBannerData } from "../Contexts/BannerContext/BannerContext";
import { ModifyWidgetItemResponse } from "../Utilities/ModifyWidgetItemResponse";

interface IRefreshProps {
  isApiCalled: boolean;
  showErrorNotification: boolean;
  widgetId: number;
  HandleWidgetApiFailure?: () => void;
  HandleSuccessNotification: () => void;
}

const defaultRefreshProps: IRefreshProps = {
  isApiCalled: false,
  showErrorNotification: false,
  widgetId: 0,
  HandleSuccessNotification: () => {}
};

const useHandleRefresh = () => {
  const { session } = useContext<SessionContextValue>(SessionContext);
  const { primeNumbers, widget, widgetItem, setWidget, setIsWidgetApiFailed, setWidgetItem } =
    useContext<WidgetContextValue>(WidgetContext);
  const [refreshCall, setRefreshCall] = useState(defaultRefreshProps);
  const { setBannerData } = useContext<BannerContextValue>(BannerContext);

  useEffect(() => {
    refreshCall.isApiCalled && HandleRefresh(refreshCall.widgetId);
    return () => {
      setRefreshCall(defaultRefreshProps);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCall.isApiCalled]);

  const HandleApiNoData = (id: number) => {
    setWidget((widgetData: IWidget[]): IWidget[] => widgetData.filter((obj: IWidget) => Number(obj.id) !== Number(id)));
  };

  const HandleRefresh = async (id: number) => {
    const getWidgetsRequestProps: IRequestProps = {
      session,
      method: "GET",
      url: `/widgets/${id}?include=widgetItems`
    };
    let widgetName = widget.find((i) => i.id == id).attributes.name;
    const informationBanner: IBannerData = {
      isBannerOpen: true,
      bannerType: "information",
      content: { title: `${widgetName} Widget has been disabled`, text: "Homepage content has been updated" }
    };

    HttpRequest(getWidgetsRequestProps)
      .then((res) => {
        const updatedData = [...widget];
        const widgetIndex = updatedData.findIndex((obj: IWidget) => Number(obj.id) === Number(id));
        updatedData[widgetIndex] = { ...updatedData[widgetIndex], ...res.data };
        updatedData[widgetIndex].attributes.refreshIntervalSecond =
          session.autoRefreshIntervalSeconds + primeNumbers[widgetIndex];
        setWidget(updatedData);

        if (!res.data.attributes.enabled) {
          setBannerData(informationBanner);
        }

        let updatedWidgetItems = ModifyWidgetItemResponse(res, widgetItem);
        setWidgetItem(updatedWidgetItems);
        refreshCall.HandleSuccessNotification();
      })
      .catch((err) => {
        if (err.statusCode === 404) {
          HandleApiNoData(id);
          setBannerData(informationBanner);
        } else {
          refreshCall.showErrorNotification && setIsWidgetApiFailed(true);
          refreshCall.showErrorNotification &&
            refreshCall?.HandleWidgetApiFailure &&
            refreshCall?.HandleWidgetApiFailure();
        }
      });
  };

  return { setRefreshCall };
};

export default useHandleRefresh;
