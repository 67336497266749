import { useEffect, useState, useContext, useRef } from "react";
import { Tooltip } from "react-tooltip";

import { Card } from "@emisgroup/ui-card";
import Refresh from "~icons/ic/outline-refresh";
import { Badge } from "@emisgroup/ui-badge";
import WidgetItems from "../../WidgetItems/Index";
import { IModifiedWidgetItem, WidgetContext, WidgetContextValue } from "../../../Contexts/Widget/WidgetContext";
import { useValidateWidgetItems } from "../../../CustomHooks/UseValidateWidgetItems";
import { WidgetItemsProps } from "../../WidgetItems/WidgetItems";
import NotificationComponent, {
  INotificationProps,
  NotificationContent
} from "../../CustomComponents/NotificationComponent/Index";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import ManageWidgetItems from "../../ManageWidgetItems/ManageWidgetItems";
import WidgetEnum from "../../../Enum/WidgetEnum";
import { WidgetContentEnum } from "../../../Enum/WidgetContentEnum";
import useHandleRefresh from "../../../CustomHooks/UseHandleRefresh";
import { DialogContext, DialogContextType } from "../../../Contexts/DialogContext/DialogContext";
import WidgetErrorContent from "../../WidgetErrorContent/WidgetErrorContent";
import { SessionContext, SessionContextValue } from "../../../Contexts/Session/SessionContext";
import { today } from "../../../Utilities/DateHelper";
import ModuleTemplateEnum from "../../../Utilities/ModuleTemplate/ModuleTemplateEnum";
import { DispatchModuleTemplateEvent } from "../../../Utilities/ModuleTemplate/Index";
import {
  IModuleTemplate,
  ModuleTemplateContext,
  ModuleTemplateContextValue
} from "../../../Contexts/ModuleTemplate/ModuleTemplate";
import { FormatDate } from "../../../Utilities/FormatDate";

import "../WidgetCard.scss";

const ScriptCard = (props: { refreshIntervalSecond: number }) => {
  const { widgetItem, widget, searchWidgetText, isSearchAvailable } = useContext<WidgetContextValue>(WidgetContext);
  const { moduleTemplate } = useContext<ModuleTemplateContextValue>(ModuleTemplateContext);
  const { session } = useContext<SessionContextValue>(SessionContext);
  const scriptItem: IModifiedWidgetItem = widgetItem?.find((item) => item?.widgetName == WidgetEnum.SCRIPTS);
  const [scriptWidgetItems, setScriptWidgetItems] = useState(undefined);
  const filteredScriptItems = useValidateWidgetItems(WidgetEnum.SCRIPTS, scriptItem?.widgetItem);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationProps, setNotificationProps] = useState<INotificationProps>({
    content: NotificationContent.default,
    isOpen: isNotificationOpen,
    notificationType: "info",
    onClose: () => {}
  });
  const [scriptContentState, setScriptContentState] = useState(WidgetContentEnum.PROGRESS_SPINNER);
  const { isDialogOpen } = useContext<DialogContextType>(DialogContext);
  const { setRefreshCall } = useHandleRefresh();
  const scriptRefreshIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const scriptIntervalStartTime = useRef<number>();
  const elapsedTime = useRef<number>(0);
  let isManualRefresh: boolean = false;
  const [scriptItemCount, setScriptItemCount] = useState(0);
  const [time, formattedDate] = FormatDate();
  const [lastRefreshedTime, setLastRefreshedTime] = useState(time);
  const [formatDate, setFormatDate] = useState(formattedDate);

  useEffect(() => {
    setScriptWidgetItems(filteredScriptItems);
    let scriptsCount = 0;

    filteredScriptItems?.forEach((element) => {
      if (element.attributes.enabled) scriptsCount += element.attributes.count;
    });

    setScriptItemCount(scriptsCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetItem]);

  // useEffect for auto refresh
  useEffect(() => {
    if (!session.autoRefreshEnabled) return;

    let adjustedRefreshInterval: number = props.refreshIntervalSecond - elapsedTime.current;

    const startTimeOut = () => {
      scriptRefreshIntervalRef.current = setTimeout(() => {
        HandleScriptRefreshClick(false);
        adjustedRefreshInterval = props.refreshIntervalSecond;
        scriptIntervalStartTime.current = Number(new Date().getTime());
        startTimeOut();
      }, adjustedRefreshInterval * 1000);
    };

    const pauseTimeOut = () => {
      clearTimeout(scriptRefreshIntervalRef.current);
    };

    if (!isDialogOpen) {
      scriptIntervalStartTime.current = Number(new Date().getTime());
      startTimeOut();
    } else {
      const scriptIntervalStopTime = Number(new Date().getTime());
      elapsedTime.current = (scriptIntervalStopTime - scriptIntervalStartTime.current) / 1000;
      adjustedRefreshInterval = props.refreshIntervalSecond - elapsedTime.current;
      pauseTimeOut();
    }

    return () => {
      clearTimeout(scriptRefreshIntervalRef.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen, props.refreshIntervalSecond]);

  useEffect(() => {
    if (scriptWidgetItems?.length === 0 || !scriptWidgetItems?.some((i) => i.attributes.enabled))
      return setScriptContentState(WidgetContentEnum.NO_CONTENT);
    setScriptContentState(WidgetContentEnum.VALID_CONTENT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptWidgetItems?.length, scriptWidgetItems]);

  useEffect(() => {
    if (WidgetEnum.SCRIPTS.toLowerCase() === searchWidgetText) {
      document.getElementById("script-card")?.scrollIntoView({ behavior: "smooth", block: "center" });
      isSearchAvailable.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWidgetText]);

  const HandleScriptRefreshClick = (showErrorNotification: boolean) => {
    isManualRefresh = showErrorNotification;
    setRefreshCall({
      isApiCalled: true,
      showErrorNotification: showErrorNotification,
      widgetId: widget.find((i) => i.attributes.name == WidgetEnum.SCRIPTS).id,
      HandleWidgetApiFailure: HandleScriptWidgetApifailure,
      HandleSuccessNotification: HandleScriptSuccessNotification
    });
  };

  const HandleScriptSuccessNotification = () => {
    const [refreshedTime, refreshedFormattedDate] = FormatDate();
    setFormatDate(refreshedFormattedDate);
    setLastRefreshedTime(refreshedTime);

    if (!isManualRefresh) return;

    setIsNotificationOpen(true);
    setNotificationProps((notificationProps) => ({
      ...notificationProps,
      content: { title: "Script widget refreshed ", text: "and content updated" },
      isOpen: true,
      notificationType: "confirmation",
      onClose: () => {
        setIsNotificationOpen(false);
      }
    }));
  };

  const HandleScriptWidgetApifailure = () => {
    setScriptContentState(WidgetContentEnum.ERROR_CONTENT);
    setScriptItemCount(0);
  };

  const TriggerScriptsModule = (e) => {
    const selectedTemplate = moduleTemplate.find((item: IModuleTemplate) => {
      return item.attributes.name === e.target.value;
    });

    if (selectedTemplate == null) {
      setIsNotificationOpen(true);
      setNotificationProps({
        content: NotificationContent.error,
        isOpen: true,
        notificationType: "error",
        onClose: () => {
          setIsNotificationOpen(false);
        }
      });
      return;
    }

    let todayReplacement = {
      "##DATE##": JSON.stringify(today)
    };

    e.target.value === ModuleTemplateEnum.OWINGS
      ? DispatchModuleTemplateEvent(selectedTemplate.attributes.module)
      : DispatchModuleTemplateEvent(selectedTemplate.attributes.module, todayReplacement);
  };

  const widgetItemsProps: WidgetItemsProps = {
    items: scriptWidgetItems,
    listItemOnClick: (e) => {
      TriggerScriptsModule(e);
    }
  };

  let scriptContent = null;

  switch (scriptContentState) {
    case WidgetContentEnum.VALID_CONTENT:
      scriptContent = <WidgetItems {...widgetItemsProps} />;
      break;

    case WidgetContentEnum.NO_CONTENT:
      scriptContent = (
        <p data-dd-action-name="no-action-text" id="empty-script" className="empty-widget-item">
          No items have been configured for this widget
        </p>
      );
      break;

    default:
      scriptContent = (
        <ProgressSpinner className="spinner" data-testid="script-progress-spinner" text={"Loading"} delay={1000} />
      );
      break;
  }

  return (
    <div className="script-container" data-testid="scripts">
      {isNotificationOpen && <NotificationComponent {...notificationProps} />}
      <Card
        data-dd-action-name="script-card"
        className={`widget-card ${WidgetEnum.SCRIPTS.toLowerCase() === searchWidgetText ? "widget-highlight" : ""}`}
        data-testid="script-card"
        id="script-card"
      >
        <div className="widget-header-container">
          <span className="widget-header-text">
            Scripts <Badge>{scriptItemCount || String(0)}</Badge>
          </span>

          <div className="widget-header-button-container">
            <span data-dd-action-name="no-action-text" className="last-refreshed-time">
              {lastRefreshedTime}
            </span>
            <button
              data-dd-action-name="script-refresh"
              className="widget-refresh-button"
              data-testid="script-refresh-button"
              data-tooltip-id="script-refresh-tooltip"
              data-tooltip-html={"Refreshed: " + lastRefreshedTime + " (" + formatDate + ")"}
              onClick={() => {
                HandleScriptRefreshClick(true);
              }}
            >
              <Tooltip id="script-refresh-tooltip" className="tooltip" data-testid="script-refresh-tooltip" />
              <Refresh
                title=""
                className="widget-header-refresh-icon"
                data-testid="refresh-icon"
                size="medium"
                color="	var(--neutral-50)"
              ></Refresh>
            </button>

            <ManageWidgetItems widgetItems={scriptWidgetItems} widgetName={WidgetEnum.SCRIPTS} />
          </div>
        </div>
        {scriptContentState === WidgetContentEnum.ERROR_CONTENT ? (
          <WidgetErrorContent />
        ) : (
          <div className="widget-item-container" data-testid="script-list-item-container">
            <span>{scriptContent}</span>
          </div>
        )}
      </Card>
    </div>
  );
};

export default ScriptCard;
