enum ActivityEnum {
  VIEW_REMINDER = 116,
  ADD_REMINDER = 117,
  DISMISS_REMINDER = 118,
  ACCESS_PT_MANAGER = 91, //To be delivered and Ready for collection
  EMERGENCY_SUPPLY_OVERDUE = 14,
  OWINGS = 16,
  ACCESS_ETP_MODULE_ENGLAND = 5, // England - Expiring Today, To be processed, To claim and Requires attention
  SCOTLAND_EPHARMACY = 1, // Scotland - Expiring Today, To be processed, To claim and Requires attention
  ACCESS_SCRIPTQ_MODULE = 36, // Wales -Expiring Today, To be processed
  WALES_ETP = 201, // Wales -To claim and Requires attention
  ACCESS_PMR = 24,
  ACCESS_HOMEPAGE = 295,
  MANAGE_WIDGET = 296,
  ACCESS_NMS = 64,
  SDM_INSTALMENTS = 81,
  ACCESS_REPEAT_MANAGEMENT = 77,
  REPEAT_PRIVATE_PRESCRIPTION = 179,
  STOCK_AVAILABILITY = 166
}

export default ActivityEnum;
