import { useContext, useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";

import { Card } from "@emisgroup/ui-card";
import Refresh from "~icons/ic/outline-refresh";
import NotificationComponent, {
  INotificationProps,
  NotificationContent
} from "../CustomComponents/NotificationComponent/NotificationComponent";
import HttpRequest, { IRequestProps } from "../../Utilities/ApiHelper/HttpRequest";
import { SessionContext, SessionContextValue } from "../../Contexts/Session/SessionContext";
import FeedItems from "./FeedItems";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import { WidgetContentEnum } from "../../Enum/WidgetContentEnum";
import { useValidateFooterItems } from "../../CustomHooks/UseValidateFooterItems";
import FooterItemsEnum from "../../Enum/FooterItemsEnum";
import { WidgetContext, WidgetContextValue } from "../../Contexts/Widget/WidgetContext";
import { DialogContext, DialogContextType } from "../../Contexts/DialogContext/DialogContext";
import { DispatchModuleTemplateEvent, ModuleTemplateEnum } from "../../Utilities/ModuleTemplate/Index";
import {
  IModuleTemplate,
  ModuleTemplateContext,
  ModuleTemplateContextValue
} from "../../Contexts/ModuleTemplate/ModuleTemplate";
import { UserDetailsContext, UserDetailsContextValue } from "../../Contexts/UserDetailsContext/UserDetailsContext";
import { today } from "../../Utilities/DateHelper";
import { FormatDate } from "../../Utilities/FormatDate";

import "./Footer.scss";

export interface IFeedItems {
  type?: string;
  attributes: {
    modulename: string;
    description: string;
    hyperlinkEnabled: boolean;
    color: string;
  };
}

export type FeedItemProps = {
  items: IFeedItems[];
  listItemOnClick?: (e: any) => void;
};

export const Footer = () => {
  const { session } = useContext<SessionContextValue>(SessionContext);
  const { userDetails } = useContext<UserDetailsContextValue>(UserDetailsContext);
  const { widget } = useContext<WidgetContextValue>(WidgetContext);
  const { moduleTemplate } = useContext<ModuleTemplateContextValue>(ModuleTemplateContext);
  const [feedItems, setFeedItems] = useState<IFeedItems[]>(undefined);
  let filteredItems = useValidateFooterItems(feedItems);
  const [feedContentState, setFeedContentState] = useState(WidgetContentEnum.PROGRESS_SPINNER);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  let refreshIntervalSecond = session.autoRefreshIntervalSeconds;
  const [notificationProps, setNotificationProps] = useState<INotificationProps>({
    content: NotificationContent.default,
    isOpen: isNotificationOpen,
    notificationType: "info",
    onClose: () => {}
  });
  const footerRefreshIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const footerIntervalStartTime = useRef<number>();
  const elapsedTime = useRef<number>(0);
  const { isDialogOpen } = useContext<DialogContextType>(DialogContext);
  const owingsDormantMonths = Object.values(userDetails.applicationSettings);
  const [time, formattedDate] = FormatDate();
  const [lastRefreshedTime, setLastRefreshedTime] = useState<string>(time);
  const [formatDate, setFormatDate] = useState<string>(formattedDate);

  useEffect(() => {
    GetFeedDetails(false, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!session.autoRefreshEnabled) return;

    let adjustedRefreshInterval: number = refreshIntervalSecond - elapsedTime.current;

    const startTimeOut = () => {
      footerRefreshIntervalRef.current = setTimeout(() => {
        GetFeedDetails(false, true);
        adjustedRefreshInterval = refreshIntervalSecond;
        footerIntervalStartTime.current = Number(new Date().getTime());
        startTimeOut();
      }, adjustedRefreshInterval * 1000);
    };

    const pauseTimeOut = () => {
      clearTimeout(footerRefreshIntervalRef.current);
    };

    if (!isDialogOpen) {
      footerIntervalStartTime.current = Number(new Date().getTime());
      startTimeOut();
    } else {
      const footerIntervalStopTime = Number(new Date().getTime());
      elapsedTime.current = (footerIntervalStopTime - footerIntervalStartTime.current) / 1000;
      adjustedRefreshInterval = refreshIntervalSecond - elapsedTime.current;
      pauseTimeOut();
    }

    return () => {
      clearTimeout(footerRefreshIntervalRef.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen, refreshIntervalSecond]);

  const TriggerFeedModule = (name: string) => {
    if (name === FooterItemsEnum.OWING) name = ModuleTemplateEnum.OWINGS_FOOTER;

    if (name == FooterItemsEnum.EMERGENCY_SUPPLY_OVERDUE) name = ModuleTemplateEnum.EMERGENCY_SUPPLY_OVERDUE_FOOTER;

    const selectedTemplate = moduleTemplate.find((item: IModuleTemplate) => {
      return item.attributes.name === name;
    });

    let owingsReplacements = {
      "##STARTDATE##": GetOwingStartDate(),
      "##ENDDATE##": today
    };

    if (selectedTemplate == null) {
      setIsNotificationOpen(true);
      setNotificationProps({
        content: NotificationContent.error,
        isOpen: true,
        notificationType: "error",
        onClose: () => {
          setIsNotificationOpen(false);
        }
      });
      return;
    }

    name == ModuleTemplateEnum.OWINGS_FOOTER
      ? DispatchModuleTemplateEvent(selectedTemplate.attributes.module, owingsReplacements)
      : DispatchModuleTemplateEvent(selectedTemplate.attributes.module);
  };

  const feedItemsProps: FeedItemProps = {
    items: filteredItems,
    listItemOnClick: (name) => {
      TriggerFeedModule(name);
    }
  };

  let feedContent = null;

  if (feedContentState == WidgetContentEnum.VALID_CONTENT) {
    feedContent = <FeedItems {...feedItemsProps} />;
  } else {
    feedContent = (
      <ProgressSpinner className="spinner" data-testid="feed-progress-spinner" text={"Loading"} delay={1000} />
    );
  }

  const GetFeedDetails = (isManualRefresh: boolean, isAutoRefresh: boolean) => {
    const getUpdatedFeedItemsRequestProps: IRequestProps = {
      session,
      method: "GET",
      url: "/footerItems"
    };

    HttpRequest(getUpdatedFeedItemsRequestProps)
      .then((res) => {
        setFeedItems(res.data);
        isManualRefresh && HandleFeedItemsSuccessNotification();
        setFeedContentState(WidgetContentEnum.VALID_CONTENT);
        const [refreshedTime, refreshedFormattedDate] = FormatDate();
        setFormatDate(refreshedFormattedDate);
        setLastRefreshedTime(refreshedTime);
      })
      .catch(() => {
        if (!isAutoRefresh) {
          setFeedContentState(WidgetContentEnum.ERROR_CONTENT);
          setIsNotificationOpen(true);
          setNotificationProps({
            content: NotificationContent.error,
            isOpen: true,
            notificationType: "error",
            onClose: () => {
              setIsNotificationOpen(false);
            }
          });
        }
      });
  };

  const HandleFeedItemsSuccessNotification = () => {
    setIsNotificationOpen(true);
    setNotificationProps((notificationProps) => ({
      ...notificationProps,
      content: { title: "Feed refreshed ", text: "and content updated" },
      isOpen: true,
      notificationType: "confirmation",
      onClose: () => {
        setIsNotificationOpen(false);
      }
    }));
  };

  const GetOwingStartDate = () => {
    const dateObj = new Date();
    dateObj.setMonth(dateObj.getMonth() - Number(owingsDormantMonths));
    const currentDate = dateObj.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(dateObj);
    const year = dateObj.getFullYear();
    return `${currentDate}-${month}-${year}`;
  };

  return (
    <>
      {widget !== undefined && (
        <div data-dd-action-name="footer-container" className="feed-container" data-testid="footer-feed">
          {isNotificationOpen && <NotificationComponent {...notificationProps} />}
          <Card data-dd-action-name="footer-container" id="feed-card" data-testid="footer-feed-card">
            <div className="feed-header-container">
              <span className="feed-header-text">Feed</span>
              <div className="feed-header-button-container">
                <span data-dd-action-name="no-action-text" className="last-refreshed-time">
                  {lastRefreshedTime}
                </span>
                <button
                  data-dd-action-name="footer-refresh"
                  className="widget-refresh-button"
                  data-testid="feed-refresh-button"
                  data-tooltip-id="feed-refresh-tooltip"
                  data-tooltip-html={"Refreshed: " + lastRefreshedTime + " (" + formatDate + ")"}
                  onClick={() => {
                    GetFeedDetails(true, false);
                  }}
                >
                  <Tooltip id="feed-refresh-tooltip" className="tooltip" data-testid="feed-refresh-tooltip" />
                  <Refresh
                    title=""
                    className="widget-header-refresh-icon"
                    data-testid="refresh-icon"
                    size="medium"
                    color="var(--neutral-50)"
                  ></Refresh>
                </button>
              </div>
            </div>
            {feedContentState === WidgetContentEnum.ERROR_CONTENT ? (
              <div data-dd-action-name="no-action-text" className="error-content-feed" data-testid="error-content-feed">
                <p>Unable to retrieve Feed data</p>
              </div>
            ) : (
              <div className="feed-item-container" data-testid="feed-list-item-container">
                <span>{feedContent}</span>
                <span data-dd-action-name="no-action-text" className="service-desk-info">
                  {session.serviceDeskInfo}
                </span>
              </div>
            )}
          </Card>
          <span data-dd-action-name="no-action-text" className="version">
            Version: 1.2.0
          </span>
        </div>
      )}
    </>
  );
};
