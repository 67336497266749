import { useContext, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

import { ListItem } from "@emisgroup/ui-list";
import { SessionContext, SessionContextValue } from "../../../Contexts/Session/SessionContext";
import { UserDetailsContext, UserDetailsContextValue } from "../../../Contexts/UserDetailsContext/UserDetailsContext";
import NotificationComponent, {
  INotificationProps,
  NotificationContent
} from "../../CustomComponents/NotificationComponent/Index";
import Clear from "~icons/ic/baseline-cancel";
import DialogComponent, { IDialogProps } from "../../CustomComponents/DialogComponent/Index";
import HttpRequest, { IRequestProps } from "../../../Utilities/ApiHelper/HttpRequest";
import { AddLineBreaks } from "../../../Utilities/AddLineBreaks";
import { DialogContext, DialogContextType } from "../../../Contexts/DialogContext/DialogContext";
import ActivityEnum from "../../../Enum/Index";
import { AuthorizeWidget } from "../../AuthorizeWidget/Index";
import { DispatchModuleTemplateEvent, ModuleTemplateEnum } from "../../../Utilities/ModuleTemplate/Index";
import {
  IModuleTemplate,
  ModuleTemplateContext,
  ModuleTemplateContextValue
} from "../../../Contexts/ModuleTemplate/ModuleTemplate";

export interface IReminderItem {
  id: number;
  title: string;
  displayTitle: string;
  notifyDate: string;
}

type Props = {
  reminderItems: IReminderItem[];
  SetFilteredReminderList?: (id: number) => void;
};

export const RemindersDataList: React.FC<Props> = (props) => {
  const { session } = useContext<SessionContextValue>(SessionContext);
  const { moduleTemplate } = useContext<ModuleTemplateContextValue>(ModuleTemplateContext);
  const { userDetails } = useContext<UserDetailsContextValue>(UserDetailsContext);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isDismissPopUpOpen, setIsDismissPopUpOpen] = useState(false);
  const [reminderId, setReminderId] = useState(0);
  const [notificationProps, setNotificationProps] = useState<INotificationProps>({
    content: NotificationContent.default,
    isOpen: isNotificationOpen,
    notificationType: "info",
    onClose: () => {}
  });
  const { setIsDialogOpen } = useContext<DialogContextType>(DialogContext);

  useEffect(() => {
    setIsDialogOpen(isDismissPopUpOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDismissPopUpOpen]);

  const TriggerReminderManager = (title: any, notifyDate: string) => {
    const selectedTemplate = moduleTemplate.find((item: IModuleTemplate) => {
      return item.attributes.name === ModuleTemplateEnum.REMINDER_MANAGER_TITLE_SEARCH;
    });

    if (selectedTemplate == null) {
      setIsNotificationOpen(true);
      setNotificationProps({
        content: NotificationContent.error,
        isOpen: true,
        notificationType: "error",
        onClose: () => {
          setIsNotificationOpen(false);
        }
      });
      return;
    }

    const replacements = {
      "##STARTDATE##": notifyDate,
      "##ENDDATE##": notifyDate,
      "##REMINDERTITLE##": JSON.stringify(title)
    };

    DispatchModuleTemplateEvent(selectedTemplate.attributes.module, replacements);
  };

  const HandleDismissOnClick = (id: number) => {
    setReminderId(id);
    setIsDismissPopUpOpen(true);
  };

  const HandleApiError = () => {
    setNotificationProps((notificationProps) => ({
      ...notificationProps,
      content: NotificationContent.error,
      isOpen: true,
      notificationType: "error",
      onClose: () => {
        setIsNotificationOpen(false);
      }
    }));
  };

  const PerformReminderDismissal = () => {
    let listItem = document.querySelector(`#list-${reminderId}`);
    let currentCancelIcon = document.querySelector(`#cancel-icon-${reminderId}`);
    const updateReminderRequestProps: IRequestProps = {
      session,
      method: "PATCH",
      url: `/reminders/${reminderId}`
    };
    setIsDismissPopUpOpen(false);

    HttpRequest(updateReminderRequestProps)
      .then(() => {
        listItem.classList.add("hide");
        currentCancelIcon.classList.add("hide");
        setTimeout(() => {
          props.SetFilteredReminderList(reminderId);
        }, 400);
      })
      .catch(() => {
        setIsNotificationOpen(true);
        HandleApiError();
      });
  };

  const dialogProps: IDialogProps = {
    dialogHeader: "Dismiss Reminder",
    dialogContent: "Are you sure you want to dismiss the reminder?",
    isDialogOpen: isDismissPopUpOpen,
    setIsDialogOpen: setIsDismissPopUpOpen,
    handleSaveButtonClick: PerformReminderDismissal,
    dialogFooterButtonSaveText: "Dismiss"
  };

  return (
    <>
      {isNotificationOpen && <NotificationComponent {...notificationProps} />}
      <div data-testid="list-item-container">
        {props.reminderItems?.map(({ id, title, displayTitle, notifyDate }) => (
          <div key={id}>
            <ListItem className="list" data-testid="list" key={id}>
              <input
                data-dd-action-name="reminder-items"
                type="button"
                value={displayTitle}
                data-testid="list-item"
                data-tooltip-id="my-tooltip"
                data-tooltip-html={AddLineBreaks(title)}
                className="fade list-item"
                tabIndex={0}
                onClick={() => {
                  TriggerReminderManager(title, notifyDate);
                }}
                id={`list-${id}`}
              />
              <Tooltip id="my-tooltip" className="tooltip" />
              <div>
                <Clear
                  data-dd-action-name="dismiss-reminder"
                  className={`reminder-dismiss ${
                    !AuthorizeWidget({
                      validationDetails: {
                        activityId: ActivityEnum.DISMISS_REMINDER
                      },
                      contextDetails: { userDetails }
                    })
                      ? "clear-icon-disabled"
                      : "clear-icon-enabled fade"
                  }`}
                  title=""
                  size="medium"
                  onClick={(e) => HandleDismissOnClick(id)}
                  data-testid={`cancel-icon-${id}`}
                  id={`cancel-icon-${id}`}
                ></Clear>
              </div>
            </ListItem>
            <hr className="reminder-list-item-line" key={title} />
          </div>
        ))}
      </div>
      {isDismissPopUpOpen && <DialogComponent {...dialogProps} />}
    </>
  );
};
