import React, { useContext, useEffect } from "react";

import { Count } from "@emisgroup/ui-count";
import { ListItem } from "@emisgroup/ui-list";
import { Tooltip } from "react-tooltip";
import { AddLineBreaks } from "../../Utilities/AddLineBreaks";
import { IWidgetItems, WidgetContext, WidgetContextValue } from "../../Contexts/Widget/WidgetContext";

export type WidgetItemsProps = {
  items: IWidgetItems[];
  listItemOnClick?: (e: any) => void;
};

const WidgetItems: React.FC<WidgetItemsProps> = (props) => {
  const { items, listItemOnClick } = props;
  const { searchWidgetText, isSearchAvailable } = useContext<WidgetContextValue>(WidgetContext);

  useEffect(() => {
    items?.map(({ id, attributes }) => {
      if (attributes.name.toLowerCase() === searchWidgetText) {
        document.getElementById(`list-${id}`)?.scrollIntoView({ behavior: "smooth", block: "center" });
        isSearchAvailable.current = true;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWidgetText]);

  const HandleClickEvent = (event) => {
    listItemOnClick(event);
  };

  return (
    <>
      {items?.map(
        ({ id, attributes }) =>
          attributes?.enabled && (
            <div key={id}>
              <ListItem
                className={`widget-list ${
                  attributes.name.toLowerCase() === searchWidgetText ? "widget-highlight" : ""
                }`}
                data-testid="script-list"
                key={id}
              >
                <div
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html={AddLineBreaks(attributes?.name)}
                  className="widget-list-item-content"
                >
                  <input
                    data-dd-action-name={attributes.name}
                    type="button"
                    data-testid={`list-item-${id}`}
                    value={attributes.name}
                    tabIndex={0}
                    onClick={(event) => {
                      HandleClickEvent(event);
                    }}
                    id={`list-${id}`}
                    disabled={attributes.count <= 0}
                    className={`widget-list-item-text ${attributes.count ? "" : "zero-count-item"}`}
                  />
                  <span className={`${attributes.count ? "widget-count-badge" : "widget-zero-count"}`}>
                    <Count value={attributes.count ? attributes.count : 0} className={"widget-count-value"} />
                  </span>
                </div>
                <Tooltip id="my-tooltip" className="tooltip" />
              </ListItem>
              <hr className="widget-list-item-line" />
            </div>
          )
      )}
    </>
  );
};

export default WidgetItems;
