import { useContext } from "react";

import { IWidgetItems } from "../Contexts/Widget/WidgetContext";
import { UserDetailsContext, UserDetailsContextValue } from "../Contexts/UserDetailsContext/UserDetailsContext";
import WidgetItemsEnum from "../Enum/WidgetItemsEnum";
import ActivityEnum from "../Enum/ActivityEnum";
import IUserDetails from "../Contexts/UserDetailsContext/IUserDetails";
import ProScriptConnectModule from "../Enum/ProScriptConnectModule";
import { SessionContext, SessionContextValue } from "../Contexts/Session/SessionContext";
import ISession from "../Contexts/Session/ISession";
import WidgetEnum from "../Enum/WidgetEnum";
import { AuthorizeWidget } from "../Components/AuthorizeWidget/Index";
import { SortWidgetItems } from "../Utilities/SortWidgetItems";

export const useValidateWidgetItems = (widget, widgetItems: IWidgetItems[]) => {
  const { session } = useContext<SessionContextValue>(SessionContext);
  const { userDetails } = useContext<UserDetailsContextValue>(UserDetailsContext);

  let enabledWidgetItems;

  if (widget === WidgetEnum.SCRIPTS) enabledWidgetItems = ValidateScriptItems(session, userDetails, widgetItems);

  if (widget === WidgetEnum.AWAITING_CLINICAL_CHECK)
    enabledWidgetItems = ValidateAwaitingClinicalCheck(session, userDetails, widgetItems);

  if (widget === WidgetEnum.RMS)
    enabledWidgetItems = widgetItems?.map((item) => {
      return item;
    });

  if (widget === WidgetEnum.PENDING) enabledWidgetItems = ValidatePending(session, userDetails, widgetItems);

  let filteredScriptItems = enabledWidgetItems
    ?.filter((i) => typeof i !== "undefined")
    .sort((currentValue, nextValue) => SortWidgetItems(currentValue, nextValue));
  return filteredScriptItems;
};

const ValidateEtpModules = (session: ISession, userDetails: IUserDetails, item: IWidgetItems) => {
  switch (userDetails?.region) {
    case 0:
      return ValidateEnglandScriptItems(session, userDetails, item);
    case 1:
      return ValidateScotlandScriptItems(session, userDetails, item);
    case 12:
      return ValidateWalesScriptItems(session, userDetails, item);
    default:
      break;
  }
};

const ValidateEnglandScriptItems = (session: ISession, userDetails: IUserDetails, item: IWidgetItems) => {
  if (
    AuthorizeWidget({
      validationDetails: {
        activityId: ActivityEnum.ACCESS_ETP_MODULE_ENGLAND,
        moduleName: [ProScriptConnectModule.ENGLAND_ETP]
      },
      contextDetails: { userDetails, session }
    })
  )
    return item;
};

const ValidateScotlandScriptItems = (session: ISession, userDetails: IUserDetails, item: IWidgetItems) => {
  if (
    AuthorizeWidget({
      validationDetails: {
        activityId: ActivityEnum.SCOTLAND_EPHARMACY,
        moduleName: [ProScriptConnectModule.SCOTLAND_EPHARMACY]
      },
      contextDetails: { userDetails, session }
    })
  )
    return item;
};

const ValidateWalesScriptItems = (session: ISession, userDetails: IUserDetails, item: IWidgetItems) => {
  switch (item.attributes.name) {
    case WidgetItemsEnum.REQUIRES_ATTENTION:
    case WidgetItemsEnum.TO_CLAIM:
      if (
        AuthorizeWidget({
          validationDetails: { activityId: ActivityEnum.WALES_ETP, moduleName: [ProScriptConnectModule.WALES_ETP] },
          contextDetails: { userDetails, session }
        })
      )
        return item;
      break;

    case WidgetItemsEnum.TO_BE_PROCESSED:
    case WidgetItemsEnum.EXPIRING_TODAY:
      if (
        AuthorizeWidget({
          validationDetails: {
            activityId: ActivityEnum.ACCESS_SCRIPTQ_MODULE,
            moduleName: [ProScriptConnectModule.WALES_ETP]
          },
          contextDetails: { userDetails, session }
        })
      )
        return item;
      break;
  }
};

const ValidateScriptItems = (session: ISession, userDetails: IUserDetails, widgetItems: IWidgetItems[]) => {
  return widgetItems?.map((item) => {
    switch (item.attributes.name) {
      case WidgetItemsEnum.EMERGENCY_SUPPLY_OVERDUE:
        if (
          AuthorizeWidget({
            validationDetails: {
              activityId: ActivityEnum.EMERGENCY_SUPPLY_OVERDUE,
              moduleName: [ProScriptConnectModule.OVERDUE_SCRIPTS]
            },
            contextDetails: { userDetails, session }
          })
        )
          return item;
        break;

      case WidgetItemsEnum.READY_FOR_COLLECTION:
      case WidgetItemsEnum.TO_BE_DELIVERED:
        if (
          AuthorizeWidget({
            validationDetails: {
              activityId: ActivityEnum.ACCESS_PT_MANAGER,
              moduleName: [ProScriptConnectModule.PRESCRIPTION_TRACKING_MANAGER]
            },
            contextDetails: { userDetails, session }
          })
        )
          return item;
        break;

      case WidgetItemsEnum.OWINGS:
        if (
          AuthorizeWidget({
            validationDetails: { activityId: ActivityEnum.OWINGS, moduleName: [ProScriptConnectModule.OWINGS] },
            contextDetails: { userDetails, session }
          })
        )
          return item;
        break;

      case WidgetItemsEnum.REQUIRES_ATTENTION:
      case WidgetItemsEnum.TO_CLAIM:
      case WidgetItemsEnum.TO_BE_PROCESSED:
      case WidgetItemsEnum.EXPIRING_TODAY:
        return ValidateEtpModules(session, userDetails, item);
    }
  });
};

const ValidateAwaitingClinicalCheck = (session: ISession, userDetails: IUserDetails, widgetItems: IWidgetItems[]) => {
  return widgetItems?.map((item) => {
    switch (item.attributes.name) {
      case WidgetItemsEnum.HUB_QUEUE_MEDIPAC:
        if (
          AuthorizeWidget({
            validationDetails: {
              activityId: ActivityEnum.ACCESS_PMR,
              moduleName: [ProScriptConnectModule.MEDIPAC]
            },
            contextDetails: { userDetails, session }
          })
        )
          return item;
        break;

      case WidgetItemsEnum.HUB_QUEUE_GOLDEN_TOTE:
        if (
          AuthorizeWidget({
            validationDetails: {
              activityId: ActivityEnum.ACCESS_PMR,
              moduleName: [ProScriptConnectModule.GOLDEN_TOTE]
            },
            contextDetails: { userDetails, session }
          })
        )
          return item;
        break;

      case WidgetItemsEnum.HUB_QUEUE_FLOWRX:
        if (
          AuthorizeWidget({
            validationDetails: {
              activityId: ActivityEnum.ACCESS_PMR,
              moduleName: [ProScriptConnectModule.FLOWRX]
            },
            contextDetails: { userDetails, session }
          })
        )
          return item;
        break;

      case WidgetItemsEnum.HUB_QUEUE_PICK_TO_PRESCRIPTION:
        if (
          AuthorizeWidget({
            validationDetails: {
              activityId: ActivityEnum.ACCESS_PMR,
              moduleName: [ProScriptConnectModule.PICK_TO_PRESCRIPTION]
            },
            contextDetails: { userDetails, session }
          })
        )
          return item;
        break;

      case WidgetItemsEnum.SCRIPT_CHECK_QUEUE:
        if (
          AuthorizeWidget({
            validationDetails: {
              moduleName: [ProScriptConnectModule.SCRIPT_CHECK]
            },
            contextDetails: { session }
          })
        )
          return item;
        break;
    }
  });
};

const ValidatePending = (session: ISession, userDetails: IUserDetails, widgetItems: IWidgetItems[]) => {
  return widgetItems?.map((item) => {
    switch (item.attributes.name) {
      case WidgetItemsEnum.NMS_ENGAGEMENT_PENDING:
      case WidgetItemsEnum.NMS_FOLLOW_UP_PENDING:
      case WidgetItemsEnum.NMS_INTERVENTION_PENDING:
        if (
          AuthorizeWidget({
            validationDetails: {
              activityId: ActivityEnum.ACCESS_NMS,
              moduleName: [ProScriptConnectModule.NMS_MANAGER]
            },
            contextDetails: { userDetails, session }
          })
        )
          return item;
        break;

      case WidgetItemsEnum.SDM_INSTALLMENTS:
        if (
          AuthorizeWidget({
            validationDetails: {
              activityId: ActivityEnum.SDM_INSTALMENTS,
              moduleName: [ProScriptConnectModule.SDM]
            },
            contextDetails: { userDetails, session }
          })
        )
          return item;
        break;
    }
  });
};
