import { useState, useContext, useEffect, useRef } from "react";
import { Tooltip } from "react-tooltip";

import { Card } from "@emisgroup/ui-card";
import { Textarea } from "@emisgroup/ui-input";
import Refresh from "~icons/ic/outline-refresh";
import Warning from "~icons/ic/baseline-warning-amber";
import { Button, ButtonGroup } from "@emisgroup/ui-button";
import HttpRequest, { IRequestProps } from "../../../Utilities/ApiHelper/HttpRequest";
import { GetFormatedDate } from "../../../Utilities/DateHelper";
import { SessionContext, SessionContextValue } from "../../../Contexts/Session/SessionContext";
import NotificationComponent, {
  INotificationProps,
  NotificationContent
} from "../../CustomComponents/NotificationComponent/Index";
import useHandleRefresh from "../../../CustomHooks/UseHandleRefresh";
import { DialogContext, DialogContextType } from "../../../Contexts/DialogContext/DialogContext";
import WidgetEnum from "../../../Enum/WidgetEnum";
import { WidgetContext, WidgetContextValue } from "../../../Contexts/Widget/WidgetContext";
import { BannerContext, BannerContextValue, IBannerData } from "../../../Contexts/BannerContext/BannerContext";
import { HtmlEncode } from "../../../Utilities/HtmlEncode/HtmlEncode";
import { FormatDate } from "../../../Utilities/FormatDate";

import "./HandoverCard.scss";
import "../WidgetCard.scss";

export interface IHandoverData {
  id: number;
  freetext: string;
  lastUpdatedDate: Date;
  lastUpdatedBy: string;
}

const defaultValue: IHandoverData = {
  id: 0,
  freetext: "",
  lastUpdatedDate: null,
  lastUpdatedBy: ""
};

export const HandoverCard = (props: { refreshIntervalSecond: number }) => {
  const { session } = useContext<SessionContextValue>(SessionContext);
  const { widget, searchWidgetText, isSearchAvailable } = useContext<WidgetContextValue>(WidgetContext);
  const [handoverData, setHandoverData] = useState<IHandoverData>(defaultValue);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { isDialogOpen } = useContext<DialogContextType>(DialogContext);
  const { setRefreshCall } = useHandleRefresh();
  const refreshIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const intervalStartTime = useRef<number>();
  const elapsedTime = useRef<number>(0);
  const { bannerData, setBannerData } = useContext<BannerContextValue>(BannerContext);
  const [notificationProps, setNotificationProps] = useState<INotificationProps>({
    content: NotificationContent.default,
    isOpen: isNotificationOpen,
    notificationType: "confirmation",
    onClose: () => {}
  });
  const [isWidgetApiPassed, setIsWidgetApiPassed] = useState(false);
  const [isHandoverApiPassed, setIsHandoverApiPassed] = useState(false);
  const [isManualRefresh, setIsManualRefresh] = useState(false);
  const isSaveDisabled = useRef<boolean>(true);
  const warningBanner: IBannerData = {
    isBannerOpen: true,
    bannerType: "warning",
    content: {
      title: `Handover Widget: Content not saved`,
      text: "Please continue editing the module and click save to complete the handover notes"
    }
  };

  const defaultBanner: IBannerData = {
    isBannerOpen: false,
    bannerType: "information",
    content: {
      title: ``,
      text: ""
    }
  };
  const [time, formattedDate] = FormatDate();
  const [lastRefreshedTime, setLastRefreshedTime] = useState(time);
  const [formatDate, setFormatDate] = useState(formattedDate);

  //useEffect for showing notification if both the api passed
  useEffect(() => {
    if (!isWidgetApiPassed || !isHandoverApiPassed) return;
    const [refreshedTime, refreshedFormattedDate] = FormatDate();
    setFormatDate(refreshedFormattedDate);
    setLastRefreshedTime(refreshedTime);
    setIsWidgetApiPassed(false);
    setIsHandoverApiPassed(false);

    if (!isManualRefresh) return;

    setIsNotificationOpen(true);
    HandleApiSuccess();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWidgetApiPassed, isHandoverApiPassed]);

  // useEffect for auto refresh
  useEffect(() => {
    if (!session.autoRefreshEnabled) return;

    let adjustedRefreshInterval: number = props.refreshIntervalSecond - elapsedTime.current;

    const startTimeOut = () => {
      refreshIntervalRef.current = setTimeout(() => {
        HandleHandoverRefreshClick(false);
        adjustedRefreshInterval = props.refreshIntervalSecond;
        intervalStartTime.current = Number(new Date().getTime());
        startTimeOut();
      }, adjustedRefreshInterval * 1000);
    };

    const pauseTimeOut = () => {
      clearTimeout(refreshIntervalRef.current);
    };

    if (!isDialogOpen && isSaveDisabled.current) {
      intervalStartTime.current = Number(new Date().getTime());
      startTimeOut();
    } else {
      const intervalStopTime = Number(new Date().getTime());
      elapsedTime.current = (intervalStopTime - intervalStartTime.current) / 1000;
      adjustedRefreshInterval = props.refreshIntervalSecond - elapsedTime.current;
      pauseTimeOut();
    }

    return () => {
      clearTimeout(refreshIntervalRef.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen, isSaveDisabled.current, props.refreshIntervalSecond]);

  const GetHandoverData = (showErrorNotification: boolean) => {
    const getHandoverDataRequestProps: IRequestProps = {
      session,
      method: "GET",
      url: "/handover"
    };

    HttpRequest(getHandoverDataRequestProps)
      .then((res) => {
        setHandoverData((handoverData) => ({
          ...handoverData,
          id: res.data.id,
          freetext: HtmlEncode(res.data.attributes.freeText),
          lastUpdatedDate: new Date(res.data.attributes.lastUpdatedDate.toString()),
          lastUpdatedBy: res.data.attributes.lastUpdatedBy
        }));
        setIsHandoverApiPassed(true);
      })
      .catch((err) => {
        setIsHandoverApiPassed(false);
        if (err.statusCode !== 404 && showErrorNotification) {
          setIsNotificationOpen(true);
          HandleApiError();
        } else {
          showErrorNotification && setHandoverData(defaultValue);
          setIsHandoverApiPassed(true);
        }
      });
  };

  useEffect(() => {
    GetHandoverData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (WidgetEnum.HANDOVER.toLowerCase() === searchWidgetText) {
      document.getElementById("handover-card")?.scrollIntoView({ behavior: "smooth", block: "center" });
      isSearchAvailable.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWidgetText]);

  const HandleApiError = () => {
    setNotificationProps({
      content: NotificationContent.error,
      isOpen: true,
      notificationType: "error",
      onClose: () => setIsNotificationOpen(false)
    });
  };

  const HandleApiSuccess = () => {
    setNotificationProps((notificationProps) => ({
      ...notificationProps,
      content: { title: "Handover widget refreshed ", text: "content updated" },
      isOpen: true,
      notificationType: "confirmation",
      onClose: () => {
        setIsNotificationOpen(false);
      }
    }));
  };

  const HandleNotSavedText = () => {
    setTimeout(() => {
      !isSaveDisabled.current && setBannerData(warningBanner);
    }, 1000);
  };

  const HandleInputTextPaste = () => {
    isSaveDisabled.current = false;
  };

  const HandleInputTextChange = (e: { target: { value: any } }) => {
    isSaveDisabled.current = false;
    setHandoverData((handoverData) => ({
      ...handoverData,
      freetext: e.target.value
    }));
  };

  const HandleInputTextSave = async () => {
    isSaveDisabled.current = true;
    bannerData.bannerType == "warning" && setBannerData(defaultBanner);
    handoverData.freetext = HtmlEncode(handoverData.freetext);

    const updateHandoverRequestProps: IRequestProps = {
      method: "PATCH",
      session,
      url: `/handover`,
      data: {
        data: {
          attributes: {
            freeText: handoverData.freetext
          },
          ...(handoverData.id !== 0 ? { id: handoverData.id } : {}),
          type: "handover"
        }
      }
    };

    await HttpRequest(updateHandoverRequestProps)
      .then((res) => {
        setHandoverData((handoverData) => ({
          ...handoverData,
          id: res.data.id,
          freetext: res.data.attributes.freeText,
          lastUpdatedDate: new Date(res.data.attributes.lastUpdatedDate.toString()),
          lastUpdatedBy: res.data.attributes.lastUpdatedBy
        }));
        setIsNotificationOpen(true);
        HandleApiSuccess();
        const [refreshedTime, refreshedFormattedDate] = FormatDate();
        setFormatDate(refreshedFormattedDate);
        setLastRefreshedTime(refreshedTime);
      })
      .catch(() => {
        setIsNotificationOpen(true);
        HandleApiError();
      });
  };

  const HandleInputTextClear = () => {
    if (handoverData.freetext === "") return;

    setHandoverData((handoverData) => ({
      ...handoverData,
      freetext: ""
    }));
    isSaveDisabled.current = false;
    setBannerData(warningBanner);
  };

  const HandleHandoverRefreshClick = (showErrorNotification: boolean) => {
    setIsManualRefresh(showErrorNotification);
    setRefreshCall({
      isApiCalled: true,
      showErrorNotification: showErrorNotification,
      widgetId: widget.find((i) => i.attributes.name == WidgetEnum.HANDOVER).id,
      HandleSuccessNotification: HandleHandoverSuccessNotification
    });
    GetHandoverData(showErrorNotification);

    isSaveDisabled.current = true;
    bannerData.bannerType == "warning" && setBannerData(defaultBanner);
  };

  const HandleHandoverSuccessNotification = () => {
    setIsWidgetApiPassed(true);
  };

  return (
    <div className="handover-container" data-testid="handover">
      {isNotificationOpen && <NotificationComponent {...notificationProps} />}
      <Card
        data-dd-action-name="handover-card"
        className={`handover-card ${WidgetEnum.HANDOVER.toLowerCase() === searchWidgetText ? "widget-highlight" : ""}`}
        data-testid="handover-card"
        id="handover-card"
      >
        <div className="widget-header-container">
          <span className="header-text">
            Handover
            {!isSaveDisabled.current && <Warning title="" className="not-saved-warning"></Warning>}
          </span>
          <div className="widget-header-button-container">
            <span data-dd-action-name="no-action-text" className="last-refreshed-time">
              {lastRefreshedTime}
            </span>
            <button
              data-dd-action-name="handover-refresh"
              className="handover-refresh"
              data-testid="handover-refresh-button"
              data-tooltip-id="handover-refresh-tooltip"
              data-tooltip-html={"Refreshed: " + lastRefreshedTime + " (" + formatDate + ")"}
              onClick={() => {
                HandleHandoverRefreshClick(true);
              }}
            >
              <Tooltip id="handover-refresh-tooltip" className="tooltip" data-testid="handover-refresh-tooltip" />
              <Refresh title="" className="handover-refresh" data-testid="handover-refresh" size="medium"></Refresh>
            </button>
          </div>
        </div>
        <div className="handover-item-container">
          <Textarea
            className="text-area"
            data-testid="text-area"
            placeholder="Add notes here..."
            maxLength={500}
            value={handoverData.freetext}
            onChange={HandleInputTextChange}
            onPaste={HandleInputTextPaste}
            onBlur={HandleNotSavedText}
          />
          <ButtonGroup className="action-buttons">
            <Button
              data-dd-action-name="handover-save"
              variant="filled"
              className="save-button"
              onClick={HandleInputTextSave}
              data-testid="save"
              disabled={isSaveDisabled.current}
            >
              Save
            </Button>
            <Button
              data-dd-action-name="handover-clear"
              borderless={true}
              className="clear-button"
              data-testid="clear"
              onClick={HandleInputTextClear}
            >
              Clear
            </Button>
          </ButtonGroup>
          {handoverData.lastUpdatedDate !== null && (
            <p className="last-updated" data-testid="last-updated">
              Last Updated: {`${handoverData.lastUpdatedBy} `}
              {handoverData.lastUpdatedBy.length > 8 && <br />}
              {`${handoverData.lastUpdatedDate?.toLocaleTimeString(navigator.language, {
                hour: "2-digit",
                minute: "2-digit"
              })} on ${GetFormatedDate(handoverData.lastUpdatedDate)}`}
            </p>
          )}
        </div>
      </Card>
    </div>
  );
};
