import { useEffect, useContext, useState } from "react";

import RenderAuthenticator from "./RenderAuthenticator";
import { SessionContext, SessionContextValue } from "../../Contexts/Session/SessionContext";
import HttpRequest, { IRequestProps } from "../../Utilities/ApiHelper/HttpRequest";
import { UserDetailsContext, UserDetailsContextValue } from "../../Contexts/UserDetailsContext/UserDetailsContext";

import "./Authenticator.scss";

const Authenticator = () => {
  const { session, setSession } = useContext<SessionContextValue>(SessionContext);
  const [authenticationStatus, setAuthenticationStatus] = useState("authenticateLoading");
  const { setUserDetails } = useContext<UserDetailsContextValue>(UserDetailsContext);

  useEffect(() => {
    document.addEventListener("HomepagePayload", payloadListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (session.sessionToken != undefined) {
      GetAuthenticatedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.sessionToken]);

  const payloadListener = (e: any) => {
    setSession(JSON.parse(e.detail));
  };

  const GetAuthenticatedData = () => {
    const getAuthDataRequestInfo: IRequestProps = {
      method: "GET",
      url: "/authenticate",
      session: session
    };

    HttpRequest(getAuthDataRequestInfo)
      .then((response) => {
        setUserDetails(response.data.attributes);
        setAuthenticationStatus("authenticationPassed");
      })
      .catch(() => {
        setAuthenticationStatus("authenticationFailed");
      });
  };

  return <RenderAuthenticator authenticationStatus={authenticationStatus} />;
};

export default Authenticator;
