export function IsPrime(num: number) {
  for (let i = 2; i <= num / 2; i++) {
    if (num % i === 0) {
      return false;
    }
  }
  return true;
}

export const GeneratePrimeNumbers = (count: number) => {
  const primeNumbers: number[] = [];
  let num = 2;

  while (primeNumbers.length < count) {
    if (IsPrime(num)) {
      primeNumbers.push(num);
    }
    num++;
  }

  return primeNumbers;
};
