import React, { createContext, useMemo, useState } from "react";

export interface DialogContextType {
  isDialogOpen: boolean;
  setIsDialogOpen: (newValue: boolean) => void;
}

export const DialogContext = createContext<DialogContextType | null>(null);

type DialogIsOpenProviderProps = {
  children: React.ReactNode;
};

export const DialogProvider = (props: DialogIsOpenProviderProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const value = useMemo(
    () => ({
      isDialogOpen,
      setIsDialogOpen
    }),
    [isDialogOpen]
  );

  return <DialogContext.Provider value={value}>{props.children}</DialogContext.Provider>;
};
