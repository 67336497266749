import { IWidget } from "../Contexts/Widget/WidgetContext";
import WidgetOrderEnum from "../Enum/WidgetOrderEnum";

export const ReorderWidgets = (widgetOrder: string, widgets: IWidget[]) => {
  const orderedWidget: IWidget[] = [];
  let orders: string[] = widgetOrder
    .split(",")
    .filter(function (item, pos, self) {
      return self.indexOf(item) == pos;
    })
    .map((i) => {
      return WidgetOrderEnum[Number(i) - 1];
    });

  const widgetNames: string[] = widgets.flatMap((widget: IWidget) => widget.attributes.name);

  orders = orders.concat(widgetNames.filter((item) => orders.indexOf(item) < 0));

  orders.forEach((i: string) => {
    widgets?.forEach((widget: IWidget) => {
      if (widget.attributes.name === i) {
        orderedWidget.push(widget);
      }
    });
  });

  return orderedWidget;
};
