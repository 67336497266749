enum ProScriptConnectModule {
  REMINDERS = "Reminders",
  PRESCRIPTION_TRACKING_MANAGER = "PrescriptionTrackingManager",
  OWINGS = "Owings",
  OVERDUE_SCRIPTS = "OverdueScripts",
  ENGLAND_ETP = "EnglandETP",
  SCOTLAND_EPHARMACY = "ScotlandEPharmacy",
  WALES_ETP = "WalesETP",
  MEDIPAC = "MediPAC",
  GOLDEN_TOTE = "GoldenTote",
  PICK_TO_PRESCRIPTION = "PickToPrescription",
  FLOWRX = "FlowRx",
  SCRIPT_CHECK = "ScriptCheck",
  WHATSNEW = "WhatsNew",
  SUPPORT_CENTRE = "SupportCentre",
  NMS_MANAGER = "NMS",
  REPEAT_PRIVATE_PRESCRIPTION = "RepeatPrivatePrescription",
  RMS = "RMS",
  SDM = "SDM",
  STOCK_AVAILABILITY = "StockAvailability",
  MDS = "MDS",
  MEDICINE_MANAGER = "MedicinesManager",
  PRESCRIPTION_AND_ITEM_PROCESSED = "PMR"
}

export default ProScriptConnectModule;
