import { createContext, useMemo, useState } from "react";

import IUserDetails from "./IUserDetails";

export type UserDetailsContextValue = {
  userDetails: IUserDetails;
  setUserDetails: (type: IUserDetails) => void;
};

export const UserDetailsContext = createContext<UserDetailsContextValue>(null);

type UserDetailsProviderProps = {
  children: React.ReactNode;
};

const UserDetailsProvider = (props: UserDetailsProviderProps) => {
  const [userDetails, setUserDetails] = useState<IUserDetails>(null);

  const value = useMemo(
    () => ({
      userDetails,
      setUserDetails
    }),
    [userDetails]
  );

  return <UserDetailsContext.Provider value={value}>{props.children}</UserDetailsContext.Provider>;
};

export default UserDetailsProvider;
