export const FormatDate = (): [string, string] => {
  let date = new Date();

  return [
    date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false
    }),
    date
      .toLocaleDateString("en-GB", {
        year: "2-digit",
        month: "short",
        day: "2-digit"
      })
      .replace(",", "")
  ];
};
