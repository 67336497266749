export enum ModuleTemplateEnum {
  ADD_REMINDER = "Add Reminder",
  REMINDER_MANAGER_TITLE_SEARCH = "Reminder Manager - Title Search",
  EMERGENCY_SUPPLY_OVERDUE = "Emergency Supply Overdue",
  OWINGS = "Owings",
  EXPIRING_TODAY = "Expiring Today",
  REQUIRES_ATTENTION = "Requires Attention",
  TO_CLAIM = "To Claim",
  TO_BE_PROCESSED = "To be Processed",
  READY_FOR_COLLECTION = "Ready for Collection",
  TO_BE_DELIVERED = "To be Delivered",
  HOMEPAGE_RELOAD = "HomePage Reload",
  PATIENT_SEARCH = "Patient Search",
  WHATS_NEW = "Whats New",
  SUPPORT_CENTRE = "Support Centre",
  HUB_QUEUE_MANAGER = "Hub Queue Manager",
  SCRIPT_CHECK_MANAGER = "ScriptCheck Manager",
  RMS = "RmsReports",
  MDS = "MDS Prep Items",
  REPEAT_PRIVATE_PRESCRIPTION = "Dispensed Repeat Private Prescription",
  OWINGS_FOOTER = "Owings - Footer",
  NMS_MANAGER = "NMS Pending",
  SDM_INSTALLMENTS = "SDM Instalments Due Today",
  MEDS_MANAGER = "MedsManager Rejected Requests",
  NEW_MEDS_MANAGER = "New MedsManager Prescription",
  EMERGENCY_SUPPLY_OVERDUE_FOOTER = "Emergency Supply Overdue - Footer"
}

export default ModuleTemplateEnum;
