enum WidgetItemsEnum {
  READY_FOR_COLLECTION = "Ready for Collection",
  TO_BE_DELIVERED = "To be Delivered",
  EMERGENCY_SUPPLY_OVERDUE = "Emergency Supply Overdue",
  OWINGS = "Owings",
  EXPIRING_TODAY = "Expiring Today",
  TO_BE_PROCESSED = "To be Processed",
  REQUIRES_ATTENTION = "Requires Attention",
  TO_CLAIM = "To Claim",
  HUB_QUEUE_MEDIPAC = "Hub Queue: MediPac",
  HUB_QUEUE_GOLDEN_TOTE = "Hub Queue: Golden Tote",
  HUB_QUEUE_FLOWRX = "Hub Queue: FlowRx",
  HUB_QUEUE_PICK_TO_PRESCRIPTION = "Hub Queue: PickToPrescription",
  SCRIPT_CHECK_QUEUE = "ScriptCheck Queue",
  WORKLOAD = "Workload",
  SURGERY_DROP_OFF = "Surgery Drop Off",
  SURGERY_COLLECTION = "Surgery Collection",
  READY_FOR_DELIVERY = "Ready for Delivery",
  COLLECTION = "Collection",
  NMS_ENGAGEMENT_PENDING = "NMS Engagement Pending",
  NMS_INTERVENTION_PENDING = "NMS Intervention Pending",
  NMS_FOLLOW_UP_PENDING = "NMS Follow Up Pending",
  SDM_INSTALLMENTS = "SDM Instalments Due Today"
}

export default WidgetItemsEnum;
