import { useContext, useEffect, useState, useRef } from "react";
import { Tooltip } from "react-tooltip";

import NotificationComponent, {
  INotificationProps
} from "../../CustomComponents/NotificationComponent/NotificationComponent";
import { Card } from "@emisgroup/ui-card";
import { Badge } from "@emisgroup/ui-badge";
import ManageWidgetItems from "../../ManageWidgetItems/ManageWidgetItems";
import { useValidateWidgetItems } from "../../../CustomHooks/UseValidateWidgetItems";
import WidgetEnum from "../../../Enum/WidgetEnum";
import WidgetItems, { WidgetItemsProps } from "../../WidgetItems/WidgetItems";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import { DialogContext, DialogContextType } from "../../../Contexts/DialogContext/DialogContext";
import Refresh from "~icons/ic/outline-refresh";
import useHandleRefresh from "../../../CustomHooks/UseHandleRefresh";
import { WidgetContentEnum } from "../../../Enum/WidgetContentEnum";
import WidgetErrorContent from "../../WidgetErrorContent/WidgetErrorContent";
import { SessionContext, SessionContextValue } from "../../../Contexts/Session/SessionContext";
import { IModifiedWidgetItem, WidgetContext } from "../../../Contexts/Widget/WidgetContext";
import WidgetItemsEnum from "../../../Enum/WidgetItemsEnum";
import ModuleTemplateEnum from "../../../Utilities/ModuleTemplate/ModuleTemplateEnum";
import { DispatchModuleTemplateEvent } from "../../../Utilities/ModuleTemplate/Index";
import {
  IModuleTemplate,
  ModuleTemplateContext,
  ModuleTemplateContextValue
} from "../../../Contexts/ModuleTemplate/ModuleTemplate";
import { FormatDate } from "../../../Utilities/FormatDate";

import "../WidgetCard.scss";

const AwaitingClinicalCheckCard = ({ refreshIntervalSecond }) => {
  const { session } = useContext<SessionContextValue>(SessionContext);
  const { widget, widgetItem, searchWidgetText, isSearchAvailable } = useContext(WidgetContext);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const awatingClinicalCheckItems: IModifiedWidgetItem = widgetItem?.find(
    (item) => item?.widgetName === WidgetEnum.AWAITING_CLINICAL_CHECK
  );
  const [awatingClinicalCheckWidgetItems, setAwatingClinicalCheckWidgetItems] = useState(undefined);
  const filteredAwatingClinicalCheckItems = useValidateWidgetItems(
    WidgetEnum.AWAITING_CLINICAL_CHECK,
    awatingClinicalCheckItems?.widgetItem
  );
  const [awaitingClinicalCheckContentState, setAwaitingClinicalCheckContentState] = useState(
    WidgetContentEnum.PROGRESS_SPINNER
  );
  const elapsedTime = useRef<number>(0);
  const refreshIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const { isDialogOpen } = useContext<DialogContextType>(DialogContext);
  const { moduleTemplate } = useContext<ModuleTemplateContextValue>(ModuleTemplateContext);
  const awaitingccIntervalStartTime = useRef<number>();
  const { setRefreshCall } = useHandleRefresh();
  const [awaitingccItemCount, setAwaitingccItemCount] = useState(0);
  const [notificationProps, setNotificationProps] = useState<INotificationProps>({
    isOpen: isNotificationOpen,
    notificationType: "info",
    onClose: () => {}
  });
  let isManualRefresh: boolean = false;
  const [time, formattedDate] = FormatDate();
  const [lastRefreshedTime, setLastRefreshedTime] = useState(time);
  const [formatDate, setFormatDate] = useState(formattedDate);

  // useEffect for auto refresh
  useEffect(() => {
    if (!session.autoRefreshEnabled) return;

    let adjustedRefreshInterval: number = refreshIntervalSecond - elapsedTime.current;

    const startTimeOut = () => {
      refreshIntervalRef.current = setTimeout(() => {
        HandleAwaitingClinicalCheckRefreshClick(false);
        adjustedRefreshInterval = refreshIntervalSecond;
        awaitingccIntervalStartTime.current = Number(new Date().getTime());
        startTimeOut();
      }, adjustedRefreshInterval * 1000);
    };

    const pauseTimeOut = () => {
      clearTimeout(refreshIntervalRef.current);
    };

    if (!isDialogOpen) {
      awaitingccIntervalStartTime.current = Number(new Date().getTime());
      startTimeOut();
    } else {
      const intervalStopTime = Number(new Date().getTime());
      elapsedTime.current = (intervalStopTime - awaitingccIntervalStartTime.current) / 1000;
      adjustedRefreshInterval = refreshIntervalSecond - elapsedTime.current;
      pauseTimeOut();
    }

    return () => {
      clearTimeout(refreshIntervalRef.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen, refreshIntervalSecond]);

  useEffect(() => {
    if (WidgetEnum.AWAITING_CLINICAL_CHECK.toLowerCase() === searchWidgetText) {
      document.getElementById("awaiting-cc-card")?.scrollIntoView({ behavior: "smooth", block: "center" });
      isSearchAvailable.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWidgetText]);

  useEffect(() => {
    setAwatingClinicalCheckWidgetItems(filteredAwatingClinicalCheckItems);

    let awaitingccCount = 0;

    filteredAwatingClinicalCheckItems?.forEach((element) => {
      if (element.attributes.enabled) awaitingccCount += element.attributes.count;
    });

    setAwaitingccItemCount(awaitingccCount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetItem]);

  useEffect(() => {
    if (
      awatingClinicalCheckWidgetItems?.length === 0 ||
      !awatingClinicalCheckWidgetItems?.some((i: { attributes: { enabled: boolean } }) => i.attributes.enabled)
    )
      return setAwaitingClinicalCheckContentState(WidgetContentEnum.NO_CONTENT);

    setAwaitingClinicalCheckContentState(WidgetContentEnum.VALID_CONTENT);
  }, [awatingClinicalCheckWidgetItems?.length, awatingClinicalCheckWidgetItems]);

  const TriggerModule = (e) => {
    const selectedItem =
      e.target.value === WidgetItemsEnum.SCRIPT_CHECK_QUEUE
        ? ModuleTemplateEnum.SCRIPT_CHECK_MANAGER
        : ModuleTemplateEnum.HUB_QUEUE_MANAGER;

    const selectedTemplate = moduleTemplate.find((item: IModuleTemplate) => {
      return item.attributes.name === selectedItem;
    });

    if (selectedTemplate == null) {
      setIsNotificationOpen(true);
      setNotificationProps({
        isOpen: true,
        notificationType: "error",
        onClose: () => {
          setIsNotificationOpen(false);
        }
      });
      return;
    }

    DispatchModuleTemplateEvent(selectedTemplate.attributes.module);
  };

  const widgetItemsProps: WidgetItemsProps = {
    items: awatingClinicalCheckWidgetItems,
    listItemOnClick: (e) => {
      TriggerModule(e);
    }
  };

  let awaitingClinicalCheckContent = null;
  switch (awaitingClinicalCheckContentState) {
    case WidgetContentEnum.VALID_CONTENT:
      awaitingClinicalCheckContent = <WidgetItems {...widgetItemsProps} />;
      break;

    case WidgetContentEnum.NO_CONTENT:
      awaitingClinicalCheckContent = (
        <p data-dd-action-name="no-action-text" id="empty-script" className="empty-widget-item">
          No items have been configured for this widget
        </p>
      );
      break;

    default:
      awaitingClinicalCheckContent = (
        <ProgressSpinner
          className="spinner"
          data-testid="awaiting-clinical-check-progress-spinner"
          text={"Loading"}
          delay={1000}
        />
      );
      break;
  }

  const HandleAwaitingClinicalCheckRefreshClick = (showErrorNotification: boolean) => {
    isManualRefresh = showErrorNotification;
    setRefreshCall({
      isApiCalled: true,
      showErrorNotification: showErrorNotification,
      widgetId: widget.find((i) => i.attributes.name === WidgetEnum.AWAITING_CLINICAL_CHECK).id,
      HandleWidgetApiFailure: HandleAwaitingClinicalCheckWidgetApifailure,
      HandleSuccessNotification: HandleAwaitingClinicalCheckSuccessNotification
    });
  };

  const HandleAwaitingClinicalCheckSuccessNotification = () => {
    const [refreshedTime, refreshedFormattedDate] = FormatDate();
    setFormatDate(refreshedFormattedDate);
    setLastRefreshedTime(refreshedTime);

    if (!isManualRefresh) return;

    setIsNotificationOpen(true);
    setNotificationProps((notificationProps) => ({
      ...notificationProps,
      content: { title: "Awaiting Clinical Check widget refreshed ", text: "and content updated" },
      isOpen: true,
      notificationType: "confirmation",
      onClose: () => {
        setIsNotificationOpen(false);
      }
    }));
  };

  const HandleAwaitingClinicalCheckWidgetApifailure = () => {
    setAwaitingClinicalCheckContentState(WidgetContentEnum.ERROR_CONTENT);
    setAwaitingccItemCount(0);
  };

  return (
    <div className="awaiting-cc-container" data-testid="awaiting-clinical-check">
      {isNotificationOpen && <NotificationComponent {...notificationProps} />}
      <Card
        data-dd-action-name="awaiting-clinical-check-card"
        className={`widget-card ${
          WidgetEnum.AWAITING_CLINICAL_CHECK.toLowerCase() === searchWidgetText ? "widget-highlight" : ""
        }`}
        data-testid="awaiting-cc-card"
        id="awaiting-cc-card"
      >
        <div className="widget-header-container">
          <span className="widget-header-text">
            Awaiting Clinical Check <Badge>{awaitingccItemCount || String(0)}</Badge>
          </span>
          <div className="widget-header-button-container">
            <span data-dd-action-name="no-action-text" className="last-refreshed-time">
              {lastRefreshedTime}
            </span>
            <button
              data-dd-action-name="awaiting-clinical-check-refresh"
              className="widget-refresh-button"
              data-testid="awaiting-clinical-check-refresh-button"
              data-tooltip-id="awaiting-clinical-check-refresh-tooltip"
              data-tooltip-html={"Refreshed: " + lastRefreshedTime + " (" + formatDate + ")"}
              onClick={() => {
                HandleAwaitingClinicalCheckRefreshClick(true);
              }}
            >
              <Tooltip
                id="awaiting-clinical-check-refresh-tooltip"
                className="tooltip"
                data-testid="awaiting-clinical-check-refresh-tooltip"
              />
              <Refresh
                title=""
                className="widget-header-refresh-icon"
                data-testid="awaitingclinicalcheck-refresh"
                size="medium"
              ></Refresh>
            </button>
            <ManageWidgetItems
              widgetItems={awatingClinicalCheckWidgetItems}
              widgetName={WidgetEnum.AWAITING_CLINICAL_CHECK}
            />
          </div>
        </div>
        {awaitingClinicalCheckContentState === WidgetContentEnum.ERROR_CONTENT ? (
          <WidgetErrorContent />
        ) : (
          <div className="widget-item-container" data-testid="awaitingclinicalcheck-list-item-container">
            <span>{awaitingClinicalCheckContent}</span>
          </div>
        )}
      </Card>
    </div>
  );
};

export default AwaitingClinicalCheckCard;
