import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";

const AuthenticateLoad = () => {
  return (
    <div>
      <div>
        <ProgressSpinner
          data-testid="progress-spinner-authentication"
          text={"Loading your experience..."}
          className="loading-card"
        />
      </div>
    </div>
  );
};

export default AuthenticateLoad;
