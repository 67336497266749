import { IWidgetItems } from "../Contexts/Widget/WidgetContext";

export const SortWidgetItems = (currentValue: IWidgetItems, nextValue: IWidgetItems) => {
  if (currentValue.attributes.name?.toUpperCase() < nextValue.attributes.name?.toUpperCase()) {
    return -1;
  }

  if (currentValue.attributes.name?.toUpperCase() > nextValue.attributes.name?.toUpperCase()) {
    return 1;
  }

  return 0;
};
