import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "../Home/Index";

import "./Layout.scss";

export const Layout = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />
    }
  ]);

  return (
    <div className="layout-container" data-testid="layout">
      <div>
        <RouterProvider router={router} />
      </div>
    </div>
  );
};
