import React from "react";

import AuthenticateLoad from "./AuthenticateLoad";
import AuthenticatedContent from "./AuthenticatedContent";
import AuthenticatedError from "./AuthenticatedError";

type RenderAuthenticatorProps = {
  authenticationStatus: string;
};
const RenderAuthenticator: React.FC<RenderAuthenticatorProps> = ({ authenticationStatus }) => {
  switch (authenticationStatus) {
    case "authenticationPassed":
      return <AuthenticatedContent />;
    case "authenticationFailed":
      return <AuthenticatedError />;
    default:
      return <AuthenticateLoad />;
  }
};

export default RenderAuthenticator;
