import { useState, useContext, useEffect, useRef } from "react";
import { Tooltip } from "react-tooltip";

import Refresh from "~icons/ic/outline-refresh";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import { TabList_v2 as TabListV2, Tab_v2 as TabV2, Tabs_v2 as TabsV2 } from "@emisgroup/ui-tabs";
import { Card } from "@emisgroup/ui-card";
import { Badge } from "@emisgroup/ui-badge";
import { SessionContext, SessionContextValue } from "../../../Contexts/Session/SessionContext";
import { RemindersDataList, IReminderItem } from "./ReminderDataList";
import { AddButton } from "./AddButton";
import DateFilterEnum from "./DateFilterEnum";
import WidgetErrorContent from "../../WidgetErrorContent/WidgetErrorContent";
import ReminderContentEnum from "./ReminderContentEnum";
import HttpRequest, { IRequestProps } from "../../../Utilities/ApiHelper/HttpRequest";
import useHandleRefresh from "../../../CustomHooks/UseHandleRefresh";
import { DialogContext, DialogContextType } from "../../../Contexts/DialogContext/DialogContext";
import WidgetEnum from "../../../Enum/WidgetEnum";
import { WidgetContext, WidgetContextValue } from "../../../Contexts/Widget/WidgetContext";
import NotificationComponent, {
  INotificationProps,
  NotificationContent
} from "../../CustomComponents/NotificationComponent/Index";
import { HtmlEncode } from "../../../Utilities/HtmlEncode/HtmlEncode";
import { FormatDate } from "../../../Utilities/FormatDate";

import "./ReminderCard.scss";
import "../WidgetCard.scss";

type reminderListType = {
  reminderList: Array<IReminderItem> | undefined;
  isEmpty: boolean;
  isApiFailed: boolean;
};

const reminderDefaultListValue: reminderListType = {
  reminderList: undefined,
  isEmpty: false,
  isApiFailed: false
};

export const ReminderCard = (props: { refreshIntervalSecond: number }) => {
  const [reminderItems, setReminderItems] = useState<IReminderItem[]>([]);
  const { widget, searchWidgetText, isSearchAvailable } = useContext<WidgetContextValue>(WidgetContext);
  const { session } = useContext<SessionContextValue>(SessionContext);
  const { isDialogOpen } = useContext<DialogContextType>(DialogContext);
  const [reminderContentValue, setReminderContentValue] = useState(ReminderContentEnum.PROGRESS_SPINNER);
  const [dateFilter, setDateFilter] = useState(DateFilterEnum.TODAY);
  const { setRefreshCall } = useHandleRefresh();
  const refreshIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const reminderIntervalStartTime = useRef<number>();
  const elapsedTime = useRef<number>(0);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationProps, setNotificationProps] = useState<INotificationProps>({
    content: NotificationContent.default,
    isOpen: isNotificationOpen,
    notificationType: "confirmation",
    onClose: () => {}
  });
  const [isWidgetApiPassed, setIsWidgetApiPassed] = useState(false);
  const [isReminderApiPassed, setIsReminderApiPassed] = useState(false);
  const [isManualRefresh, setIsManualRefresh] = useState(false);
  const [todayList, setTodayList] = useState<reminderListType>(reminderDefaultListValue);
  const [tomorrowList, setTomorrowList] = useState<reminderListType>(reminderDefaultListValue);
  const [weekList, setWeekList] = useState<reminderListType>(reminderDefaultListValue);
  const [time, formattedDate] = FormatDate();
  const [lastRefreshedTime, setLastRefreshedTime] = useState(time);
  const [formatDate, setFormatDate] = useState(formattedDate);

  //useEffect for showing notification if both the api passed
  useEffect(() => {
    if (!isWidgetApiPassed || !isReminderApiPassed) return;
    const [refreshedTime, refreshedFormattedDate] = FormatDate();
    setFormatDate(refreshedFormattedDate);
    setLastRefreshedTime(refreshedTime);
    setIsWidgetApiPassed(false);
    setIsReminderApiPassed(false);

    if (!isManualRefresh) return;

    setIsNotificationOpen(true);
    setNotificationProps((notificationProps) => ({
      ...notificationProps,
      content: { title: "Reminders widget refreshed ", text: "and content updated" },
      isOpen: true,
      notificationType: "confirmation",
      onClose: () => {
        setIsNotificationOpen(false);
      }
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWidgetApiPassed, isReminderApiPassed]);

  const SetFilteredReminderList = (dismissedReminderId) => {
    setIsNotificationOpen(true);
    setNotificationProps((notificationProps) => ({
      ...notificationProps,
      content: { title: "Reminder dismissed", text: "" },
      isOpen: true,
      notificationType: "confirmation",
      onClose: () => {
        setIsNotificationOpen(false);
      }
    }));
    const filteredTodayList = {
      ...todayList,
      reminderList: todayList.reminderList.filter((i) => i.id != dismissedReminderId)
    };
    setTodayList(filteredTodayList);

    const filterTomorrowList = {
      ...tomorrowList,
      reminderList: tomorrowList.reminderList.filter((i) => i.id != dismissedReminderId)
    };
    setTomorrowList(filterTomorrowList);

    const filterWeekList = {
      ...weekList,
      reminderList: weekList.reminderList.filter((i) => i.id != dismissedReminderId)
    };
    setWeekList(filterWeekList);

    setIsNotificationOpen(true);
    setNotificationProps((notificationProps) => ({
      ...notificationProps,
      content: { title: "Reminder dismissed", text: "" },
      isOpen: true,
      notificationType: "confirmation",
      onClose: () => {
        setIsNotificationOpen(false);
      }
    }));
  };

  // useEffect for auto refresh
  useEffect(() => {
    if (!session.autoRefreshEnabled) return;

    let adjustedRefreshInterval: number = props.refreshIntervalSecond - elapsedTime.current;

    const startTimeOut = () => {
      refreshIntervalRef.current = setTimeout(() => {
        HandleReminderRefreshClick(false);
        adjustedRefreshInterval = props.refreshIntervalSecond;
        reminderIntervalStartTime.current = Number(new Date().getTime());
        startTimeOut();
      }, adjustedRefreshInterval * 1000);
    };

    const pauseTimeOut = () => {
      clearTimeout(refreshIntervalRef.current);
    };

    if (!isDialogOpen) {
      reminderIntervalStartTime.current = Number(new Date().getTime());
      startTimeOut();
    } else {
      const intervalStopTime = Number(new Date().getTime());
      elapsedTime.current = (intervalStopTime - reminderIntervalStartTime.current) / 1000;
      adjustedRefreshInterval = props.refreshIntervalSecond - elapsedTime.current;
      pauseTimeOut();
    }

    return () => {
      clearTimeout(refreshIntervalRef.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen, props.refreshIntervalSecond]);

  useEffect(() => {
    GetReminderDataDetails(DateFilterEnum.TODAY, true);
    GetReminderDataDetails(DateFilterEnum.TOMORROW, true);
    GetReminderDataDetails(DateFilterEnum.THIS_WEEK, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    SetFilteredItems(dateFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todayList, tomorrowList, weekList]);

  useEffect(() => {
    if (WidgetEnum.REMINDERS.toLowerCase() === searchWidgetText) {
      document.getElementById("reminders-card")?.scrollIntoView({ behavior: "smooth", block: "center" });
      isSearchAvailable.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWidgetText]);

  const HandleReminderRefreshClick = (showErrorNotification: boolean) => {
    setIsManualRefresh(showErrorNotification);
    setRefreshCall({
      isApiCalled: true,
      showErrorNotification: showErrorNotification,
      widgetId: widget.find((i) => i.attributes.name == WidgetEnum.REMINDERS).id,
      HandleSuccessNotification: HandleReminderSuccessNotification
    });
    GetReminderDataDetails(DateFilterEnum.TODAY, showErrorNotification);
    GetReminderDataDetails(DateFilterEnum.TOMORROW, showErrorNotification);
    GetReminderDataDetails(DateFilterEnum.THIS_WEEK, showErrorNotification);
  };

  const GetReminderList = (dateRangeType: DateFilterEnum, formatReminderData: IReminderItem[]) => {
    switch (dateRangeType) {
      case DateFilterEnum.TODAY:
        setTodayList({ ...todayList, reminderList: formatReminderData, isEmpty: false, isApiFailed: false });
        break;
      case DateFilterEnum.TOMORROW:
        setTomorrowList({ ...tomorrowList, reminderList: formatReminderData, isEmpty: false, isApiFailed: false });
        break;
      case DateFilterEnum.THIS_WEEK:
        setWeekList({ ...weekList, reminderList: formatReminderData, isEmpty: false, isApiFailed: false });
        break;
    }
  };

  const SetNoReminder = (dateRangeType: DateFilterEnum) => {
    switch (dateRangeType) {
      case DateFilterEnum.TODAY:
        setTodayList({ ...todayList, reminderList: [], isEmpty: true, isApiFailed: false });
        break;
      case DateFilterEnum.TOMORROW:
        setTomorrowList({ ...tomorrowList, reminderList: [], isEmpty: true, isApiFailed: false });
        break;
      case DateFilterEnum.THIS_WEEK:
        setWeekList({ ...weekList, reminderList: [], isEmpty: true, isApiFailed: false });
        break;
    }
  };

  const SetErrorOnReminders = (dateRangeType: DateFilterEnum, showErrorNotification: boolean) => {
    switch (dateRangeType) {
      case DateFilterEnum.TODAY:
        showErrorNotification && setTodayList({ ...todayList, reminderList: [], isEmpty: false, isApiFailed: true });
        break;
      case DateFilterEnum.TOMORROW:
        showErrorNotification &&
          setTomorrowList({ ...tomorrowList, reminderList: [], isEmpty: false, isApiFailed: true });
        break;
      case DateFilterEnum.THIS_WEEK:
        showErrorNotification && setWeekList({ ...weekList, reminderList: [], isEmpty: false, isApiFailed: true });
        break;
    }
  };

  const HandleApiError = () => {
    setNotificationProps((notificationProps) => ({
      ...notificationProps,
      content: NotificationContent.error,
      isOpen: true,
      notificationType: "error",
      onClose: () => {
        setIsNotificationOpen(false);
      }
    }));
  };

  const GetReminderDataDetails = (dateRangeType: DateFilterEnum, showErrorNotification: boolean) => {
    showErrorNotification && setReminderContentValue(ReminderContentEnum.PROGRESS_SPINNER);
    const getReminderDataRequestProps: IRequestProps = {
      session,
      method: "GET",
      url: "/reminders",
      params: {
        dateRangeType
      }
    };

    HttpRequest(getReminderDataRequestProps)
      .then((res) => {
        let formatReminderData: IReminderItem[] = [];
        res.data?.map((reminderData) => {
          formatReminderData.push({
            id: reminderData.id,
            title: reminderData.attributes.title,
            displayTitle: HtmlEncode(reminderData.attributes.title),
            notifyDate: reminderData.attributes.notifyDate
          });
        });

        GetReminderList(dateRangeType, formatReminderData);
        setIsReminderApiPassed(true);
      })
      .catch((err) => {
        if (err.statusCode === 404) {
          SetNoReminder(dateRangeType);
          setIsReminderApiPassed(true);
        } else {
          SetErrorOnReminders(dateRangeType, showErrorNotification);
          showErrorNotification && setIsNotificationOpen(true);
          showErrorNotification && HandleApiError();
          setIsReminderApiPassed(false);
        }
      });
  };

  const HandleDateFilterClick = (value) => {
    setDateFilter(value);
    SetFilteredItems(value);
  };

  const HandleFilterItems = (list: reminderListType) => {
    if (list.reminderList?.length) {
      setReminderItems(list.reminderList);
      setReminderContentValue(ReminderContentEnum.REMINDERS);
    } else {
      (list.reminderList?.length === 0 || list.isEmpty === true) &&
        list.isApiFailed === false &&
        setReminderContentValue(ReminderContentEnum.NO_REMINDERS);
      list.isApiFailed === true && setReminderContentValue(ReminderContentEnum.ERROR_CONTENT);
    }
  };

  const SetFilteredItems = (value: DateFilterEnum) => {
    switch (value) {
      case DateFilterEnum.TODAY:
        HandleFilterItems(todayList);
        break;
      case DateFilterEnum.TOMORROW:
        HandleFilterItems(tomorrowList);
        break;
      case DateFilterEnum.THIS_WEEK:
        HandleFilterItems(weekList);
        break;
    }
  };
  const HandleReminderSuccessNotification = () => {
    setIsWidgetApiPassed(true);
  };

  let reminderContent = null;

  switch (reminderContentValue) {
    case ReminderContentEnum.REMINDERS:
      reminderContent = (
        <RemindersDataList reminderItems={reminderItems} SetFilteredReminderList={SetFilteredReminderList} />
      );
      break;

    case ReminderContentEnum.NO_REMINDERS:
      reminderContent = (
        <p data-dd-action-name="no-action-text" id="empty-reminders">
          {" "}
          No Reminders{" "}
        </p>
      );
      break;

    default:
      reminderContent = (
        <ProgressSpinner className="spinner" data-testid="reminder-progress-spinner" text={"Loading"} delay={1000} />
      );
      break;
  }

  return (
    <div className="reminder-container" data-testid="reminders">
      {isNotificationOpen && <NotificationComponent {...notificationProps} />}
      <Card
        data-dd-action-name="reminder-card"
        className={`reminder-card ${WidgetEnum.REMINDERS.toLowerCase() === searchWidgetText ? "widget-highlight" : ""}`}
        data-testid="reminders-card"
        id="reminders-card"
      >
        <div className="widget-header-container">
          <span className="reminder-header-text">Reminders</span>
          <div className="widget-header-button-container">
            <span data-dd-action-name="no-action-text" className="last-refreshed-time">
              {lastRefreshedTime}
            </span>
            <button
              data-dd-action-name="reminder-refresh"
              className="reminder-refresh-button"
              data-testid="reminder-refresh-button"
              data-tooltip-id="reminder-refresh-tooltip"
              data-tooltip-html={"Refreshed: " + lastRefreshedTime + " (" + formatDate + ")"}
              onClick={() => {
                HandleReminderRefreshClick(true);
              }}
            >
              <Tooltip id="reminder-refresh-tooltip" className="tooltip" data-testid="reminder-refresh-tooltip" />
              <Refresh
                title=""
                className="refresh-icon"
                data-testid="refresh-icon"
                size="medium"
                color="	var(--neutral-50)"
              ></Refresh>
            </button>
          </div>
        </div>
        <TabsV2 activationMode="manual" defaultValue="today" className="reminder-tabs">
          <TabListV2>
            <TabV2
              data-dd-action-name="reminder-today-tab"
              value="today"
              onClick={() => {
                HandleDateFilterClick(DateFilterEnum.TODAY);
              }}
              className="tab-today"
            >
              Today
              <Badge className="reminder-badge">{todayList.reminderList?.length || String(0)}</Badge>
            </TabV2>
            <TabV2
              data-dd-action-name="reminder-tomorrow-tab"
              value="tomorrow"
              onClick={() => {
                HandleDateFilterClick(DateFilterEnum.TOMORROW);
              }}
              className="tab-tomorrow"
            >
              Tomorrow <Badge className="reminder-badge">{tomorrowList.reminderList?.length || String(0)}</Badge>
            </TabV2>
            <TabV2
              data-dd-action-name="reminder-week-tab"
              value="week"
              onClick={() => {
                HandleDateFilterClick(DateFilterEnum.THIS_WEEK);
              }}
              className="tab-week"
            >
              Week
              <Badge className="reminder-badge">{weekList.reminderList?.length || String(0)}</Badge>
            </TabV2>
          </TabListV2>
        </TabsV2>
        <div className="reminder-tabs-border"></div>
        {reminderContentValue === ReminderContentEnum.ERROR_CONTENT ? (
          <WidgetErrorContent />
        ) : (
          <div className="reminder-item-container">
            <div className="reminder-item">
              <span>{reminderContent}</span>
            </div>
            <AddButton />
          </div>
        )}
      </Card>
    </div>
  );
};
