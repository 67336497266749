import { useContext, useState } from "react";

import { Button } from "@emisgroup/ui-button";
import Star from "~icons/ic/outline-star-border";
import NotificationComponent, { INotificationProps } from "../../CustomComponents/NotificationComponent/Index";
import ModuleTemplateEnum from "../../../Utilities/ModuleTemplate/ModuleTemplateEnum";
import {
  IModuleTemplate,
  ModuleTemplateContext,
  ModuleTemplateContextValue
} from "../../../Contexts/ModuleTemplate/ModuleTemplate";
import { DispatchModuleTemplateEvent } from "../../../Utilities/ModuleTemplate/Index";

import "./WhatsNew.scss";

const WhatsNew = () => {
  const { moduleTemplate } = useContext<ModuleTemplateContextValue>(ModuleTemplateContext);
  const [isErrorNotificationOpen, setIsErrorNotificationOpen] = useState(false);

  const HandleButtonClick = () => {
    const selectedTemplate = moduleTemplate.find((item: IModuleTemplate) => {
      return item.attributes.name === ModuleTemplateEnum.WHATS_NEW;
    });

    if (selectedTemplate == null) {
      setIsErrorNotificationOpen(true);
      return;
    }

    DispatchModuleTemplateEvent(selectedTemplate.attributes.module);
  };

  const errorNotificationProps: INotificationProps = {
    notificationType: "error",
    isOpen: isErrorNotificationOpen,
    onClose: () => setIsErrorNotificationOpen(false)
  };

  return (
    <>
      {isErrorNotificationOpen && <NotificationComponent {...errorNotificationProps} />}
      <div>
        <Button
          data-dd-action-name="whats-new"
          className="whatsnew"
          borderless={true}
          data-testid="whats-new"
          onClick={HandleButtonClick}
        >
          <Star title={""} className="whats-new-icon"></Star>
          What's New
        </Button>
      </div>
    </>
  );
};

export default WhatsNew;
