import React, { createContext, useMemo, useState } from "react";

export interface BannerContextValue {
  bannerData: IBannerData;
  setBannerData: React.Dispatch<React.SetStateAction<IBannerData>>;
}

export interface IBannerContentProps {
  title: string;
  text?: string;
}

export type IBannerData = {
  isBannerOpen: boolean;
  bannerType: "information" | "warning";
  content: IBannerContentProps;
};

const bannerInitialValue: IBannerData = {
  isBannerOpen: false,
  bannerType: "information",
  content: { title: "", text: "" }
};

export const BannerContext = createContext<BannerContextValue | null>(null);

type BannerProviderProps = {
  children: React.ReactNode;
};

export const BannerProvider = (props: BannerProviderProps) => {
  const [bannerData, setBannerData] = useState<IBannerData>(bannerInitialValue);

  const value = useMemo(
    () => ({
      bannerData,
      setBannerData
    }),
    [bannerData]
  );

  return <BannerContext.Provider value={value}>{props.children}</BannerContext.Provider>;
};
