import { useEffect } from "react";

import { Button, ButtonGroup } from "@emisgroup/ui-button";
import { Checkbox } from "@emisgroup/ui-checkbox";
import { Dialog, Header, Content, Footer } from "@emisgroup/ui-dialog";
import { IWidget } from "../../../Contexts/Widget/WidgetContext";

import "./DialogComponent.scss";

export interface IDialogProps {
  dialogHeader: string;
  dialogContent: string;
  isDialogOpen: boolean;
  setIsDialogOpen: (newValue: boolean) => void;
  dialogCheckboxContent?: IWidget[];
  handleSaveButtonClick: () => void;
  onCheckboxStateChanged?: (id: number) => void;
  dialogFooterButtonSaveText: string;
}

const DialogComponent = (props: IDialogProps) => {
  useEffect(() => {
    const handleEscapeClick = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        props.setIsDialogOpen(false);
      }
    };

    window.addEventListener("keydown", handleEscapeClick);
    return () => {
      window.removeEventListener("keydown", handleEscapeClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      ariaLabel="persistent"
      isOpened={props.isDialogOpen}
      onClose={() => props.setIsDialogOpen(false)}
      id="homepage-dialog"
      preventBackdropClose={true}
      data-testid="homepage-dialog-test"
    >
      <Header onClose={() => props.setIsDialogOpen(false)}>
        <div>{props.dialogHeader}</div>
      </Header>
      <Content id="dialog-content">
        <p>{props.dialogContent}</p>
        {props.dialogCheckboxContent?.map(({ id, attributes }) => (
          <Checkbox
            className="dialog-checkbox"
            key={id}
            data-testid={`check-${attributes.name}`}
            checked={attributes.enabled}
            onChange={() => {
              props.onCheckboxStateChanged(id);
            }}
          >
            {attributes.name}
          </Checkbox>
        ))}
      </Content>
      <Footer id="dialog-footer">
        <ButtonGroup>
          <Button
            data-dd-action-name={props.dialogFooterButtonSaveText}
            type="button"
            variant="filled"
            onClick={props.handleSaveButtonClick}
            data-testid="btn-dialog-save"
          >
            {props.dialogFooterButtonSaveText}
          </Button>
          <Button
            data-dd-action-name="dialog-cancel"
            borderless={true}
            type="button"
            onClick={() => props.setIsDialogOpen(false)}
            data-testid="btn-dialog-cancel"
          >
            Cancel
          </Button>
        </ButtonGroup>
      </Footer>
    </Dialog>
  );
};

export default DialogComponent;
