import { useContext, useEffect } from "react";

import { Banner } from "@emisgroup/ui-banner";
import Info from "~icons/ic/outline-info";
import Warning from "~icons/ic/baseline-warning-amber";
import Close from "~icons/ic/baseline-close";
import { BannerContext, BannerContextValue } from "../../../Contexts/BannerContext/BannerContext";

import "./BannerComponent.scss";

const BannerComponent = () => {
  const { bannerData, setBannerData } = useContext<BannerContextValue>(BannerContext);

  useEffect(() => {
    if (bannerData.isBannerOpen) {
      document.getElementById("banner")?.scrollIntoView({ behavior: "smooth" });
    }
  }, [bannerData.isBannerOpen]);

  const HandleCloseClick = () => {
    setBannerData((bannerData) => ({
      ...bannerData,
      isBannerOpen: false
    }));
  };

  return (
    <div className="banner-container" id="banner">
      <Banner color={bannerData.bannerType} className="banner" data-testid="banner">
        {bannerData.bannerType == "information" ? <Info title="" /> : <Warning title="" />}
        <div>
          <div>
            <h6>{bannerData.content.title}</h6>
            <p>{bannerData.content.text}</p>
          </div>
          <Close
            data-dd-action-name="banner-close"
            title={""}
            onClick={HandleCloseClick}
            data-testid="banner-close"
          ></Close>
        </div>
      </Banner>
    </div>
  );
};

export default BannerComponent;
