import { useContext, useState } from "react";

import { UserDetailsContext, UserDetailsContextValue } from "../../Contexts/UserDetailsContext/UserDetailsContext";
import PatientSearchBar from "../SearchBar/PatientSearchBar/Index";
import ActivityEnum from "../../Enum/ActivityEnum";
import WhatsNew from "../SignPostingLinks/WhatsNew/Index";
import ManageWidgetsButton from "../ManageWidgets/Index";
import { BannerContext, BannerContextValue } from "../../Contexts/BannerContext/BannerContext";
import { AuthorizeWidget } from "../AuthorizeWidget/Index";
import BannerComponent from "../CustomComponents/BannerComponent/Index";
import SupportCentre from "../SignPostingLinks/SupportCentre/Index";
import { WidgetContext, WidgetContextValue } from "../../Contexts/Widget/WidgetContext";
import ProScriptConnectModule from "../../Enum/ProScriptConnectModule";
import { SessionContext, SessionContextValue } from "../../Contexts/Session/SessionContext";
import WidgetSearchBar from "../SearchBar/WidgetSearchBar/Index";

import "./TopBar.scss";

export const TopBar = (props: { widgetEnable: boolean }) => {
  const { userDetails } = useContext<UserDetailsContextValue>(UserDetailsContext);
  const { bannerData } = useContext<BannerContextValue>(BannerContext);
  const { widget } = useContext<WidgetContextValue>(WidgetContext);
  const { session } = useContext<SessionContextValue>(SessionContext);
  const [currentDate, setCurrentDate] = useState<string>();

  setInterval(() => {
    //assign interval to a variable to clear it.
    let formattedDate: string = new Date()
      .toLocaleDateString("en-GB", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
      })
      .replace(/,/g, "");
    setCurrentDate(formattedDate);
  }, 1000);

  return (
    <>
      {widget !== undefined && (
        <div data-dd-action-name="topbar-container" className="topbar-container" data-testid="topbar">
          <div className="topbar-first-row">
            {bannerData.isBannerOpen ? (
              <span>
                <BannerComponent />
              </span>
            ) : (
              <span className="welcome" data-dd-action-name="no-action-text">
                Hi {userDetails?.givenName}
              </span>
            )}
            {AuthorizeWidget({
              validationDetails: {
                activityId: ActivityEnum.ACCESS_PMR
              },
              contextDetails: { userDetails }
            }) && (
              <span className="span-search-bar" data-testid="span-search-bar">
                <PatientSearchBar />
                {props.widgetEnable && (
                  <span className="search-bar-or" data-dd-action-name="no-action-text">
                    Or
                  </span>
                )}
                {props.widgetEnable && <WidgetSearchBar />}
              </span>
            )}
          </div>
          <div className="topbar-second-row">
            <span className="buttons">
              <span className="manage-widgets-button" data-testid="manage-widgets-btn">
                <ManageWidgetsButton />
              </span>
              {AuthorizeWidget({
                validationDetails: {
                  moduleName: [ProScriptConnectModule.WHATSNEW]
                },
                contextDetails: { session }
              }) && (
                <span className="whatsnew-button" data-testid="whatsnew-btn">
                  <WhatsNew />
                </span>
              )}
              {AuthorizeWidget({
                validationDetails: {
                  moduleName: [ProScriptConnectModule.SUPPORT_CENTRE]
                },
                contextDetails: { session }
              }) && (
                <span className="support-centre-button" data-testid="support-centre-btn">
                  <SupportCentre />
                </span>
              )}
            </span>
            <span className="current-date" data-testid="current-date" data-dd-action-name="no-action-text">
              {currentDate}
            </span>
          </div>
        </div>
      )}
    </>
  );
};
